"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatRejectionDisplay = exports.VerificationEligibilities = exports.RejectionReasonsDisplays = exports.VerificationRejectionReasons = exports.VerificationStatus = void 0;
var VerificationStatus;
(function (VerificationStatus) {
    VerificationStatus["INELIGIBLE"] = "ineligible";
    VerificationStatus["PENDING"] = "pending";
    VerificationStatus["WATCH"] = "watch";
    VerificationStatus["APPROVED"] = "approved";
    VerificationStatus["REJECTED"] = "rejected";
})(VerificationStatus = exports.VerificationStatus || (exports.VerificationStatus = {}));
var VerificationRejectionReasons;
(function (VerificationRejectionReasons) {
    VerificationRejectionReasons["INCORRECT_SOCIALS"] = "incorrect_socials";
    VerificationRejectionReasons["LOW_SOCIAL_ENGAGEMENT"] = "low_social_engagement";
    VerificationRejectionReasons["SPAMMING_APPLICATIONS"] = "spamming_applications";
    VerificationRejectionReasons["TEAM_DISCRETION"] = "team_discretion";
    VerificationRejectionReasons["BLACKLIST"] = "blacklist";
})(VerificationRejectionReasons = exports.VerificationRejectionReasons || (exports.VerificationRejectionReasons = {}));
exports.RejectionReasonsDisplays = {
    [VerificationRejectionReasons.INCORRECT_SOCIALS]: 'Incorrect or personal socials',
    [VerificationRejectionReasons.LOW_SOCIAL_ENGAGEMENT]: 'Low social engagement',
    [VerificationRejectionReasons.SPAMMING_APPLICATIONS]: 'Spamming Applications',
    [VerificationRejectionReasons.TEAM_DISCRETION]: 'Team / Community Discretion',
    [VerificationRejectionReasons.BLACKLIST]: 'Silent / Blacklist',
};
var VerificationEligibilities;
(function (VerificationEligibilities) {
    VerificationEligibilities["APPLY"] = "apply";
    VerificationEligibilities["PENDING"] = "pending";
    VerificationEligibilities["INELIGIBLE"] = "ineligible";
    VerificationEligibilities["APPROVED"] = "approved";
    VerificationEligibilities["REJECTED"] = "rejected";
    VerificationEligibilities["REJECTED_REAPPLY"] = "rejected_reapply";
})(VerificationEligibilities = exports.VerificationEligibilities || (exports.VerificationEligibilities = {}));
function formatRejectionDisplay(rejectionReason, additionalNote, compact = true) {
    let displayStr = '';
    if (rejectionReason) {
        displayStr = `${compact ? 'Rejected: ' : 'Review result: Rejected - '} ${exports.RejectionReasonsDisplays[rejectionReason]}`;
    }
    else {
        displayStr = compact ? 'Rejected' : 'Review result: Rejected';
    }
    if (additionalNote) {
        displayStr += ` (${additionalNote})`;
    }
    return displayStr;
}
exports.formatRejectionDisplay = formatRejectionDisplay;
