"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEditionMarkPda = exports.deprecatedGetReservationList = exports.getMetadata = exports.getMetadataAddress = exports.getEdition = exports.mintNewEditionFromMasterEditionViaToken = exports.deprecatedMintNewEditionFromMasterEditionViaPrintingToken = exports.createMasterEdition = exports.createMetadata = exports.updateMetadata = exports.decodeMasterEdition = exports.decodeEdition = exports.decodeEditionMarker = exports.decodeMetadata = exports.METADATA_SCHEMA = exports.Metadata = exports.Data = exports.Creator = exports.Edition = exports.EditionMarker = exports.MasterEditionV2 = exports.MasterEditionV1 = exports.MetadataCategory = exports.MetadataKey = void 0;
const web3_js_1 = require("@solana/web3.js");
const borsh_1 = require("borsh");
const id_1 = require("./id");
const token_1 = require("./token");
const EDITION = 'edition';
const RESERVATION = 'reservation';
const MAX_NAME_LENGTH = 32;
const MAX_SYMBOL_LENGTH = 10;
const MAX_URI_LENGTH = 200;
const MAX_CREATOR_LIMIT = 5;
const MAX_CREATOR_LEN = 32 + 1 + 1;
const MAX_METADATA_LEN = 1 +
    32 +
    32 +
    MAX_NAME_LENGTH +
    MAX_SYMBOL_LENGTH +
    MAX_URI_LENGTH +
    MAX_CREATOR_LIMIT * MAX_CREATOR_LEN +
    2 +
    1 +
    1 +
    198;
const MAX_EDITION_LEN = 1 + 32 + 8 + 200;
const EDITION_MARKER_BIT_SIZE = 248;
var MetadataKey;
(function (MetadataKey) {
    MetadataKey[MetadataKey["Uninitialized"] = 0] = "Uninitialized";
    MetadataKey[MetadataKey["MetadataV1"] = 4] = "MetadataV1";
    MetadataKey[MetadataKey["EditionV1"] = 1] = "EditionV1";
    MetadataKey[MetadataKey["MasterEditionV1"] = 2] = "MasterEditionV1";
    MetadataKey[MetadataKey["MasterEditionV2"] = 6] = "MasterEditionV2";
    MetadataKey[MetadataKey["EditionMarker"] = 7] = "EditionMarker";
})(MetadataKey = exports.MetadataKey || (exports.MetadataKey = {}));
var MetadataCategory;
(function (MetadataCategory) {
    MetadataCategory["Audio"] = "audio";
    MetadataCategory["Video"] = "video";
    MetadataCategory["Image"] = "image";
    MetadataCategory["VR"] = "vr";
})(MetadataCategory = exports.MetadataCategory || (exports.MetadataCategory = {}));
class MasterEditionV1 {
    constructor(args) {
        Object.defineProperty(this, "key", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "supply", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "maxSupply", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        /// Can be used to mint tokens that give one-time permission to mint a single limited edition.
        Object.defineProperty(this, "printingMint", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        /// If you don't know how many printing tokens you are going to need, but you do know
        /// you are going to need some amount in the future, you can use a token from this mint.
        /// Coming back to token metadata with one of these tokens allows you to mint (one time)
        /// any number of printing tokens you want. This is used for instance by Auction Manager
        /// with participation NFTs, where we dont know how many people will bid and need participation
        /// printing tokens to redeem, so we give it ONE of these tokens to use after the auction is over,
        /// because when the auction begins we just dont know how many printing tokens we will need,
        /// but at the end we will. At the end it then burns this token with token-metadata to
        /// get the printing tokens it needs to give to bidders. Each bidder then redeems a printing token
        /// to get their limited editions.
        Object.defineProperty(this, "oneTimePrintingAuthorizationMint", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.key = MetadataKey.MasterEditionV1;
        this.supply = args.supply;
        this.maxSupply = args.maxSupply;
        this.printingMint = args.printingMint;
        this.oneTimePrintingAuthorizationMint =
            args.oneTimePrintingAuthorizationMint;
    }
}
exports.MasterEditionV1 = MasterEditionV1;
class MasterEditionV2 {
    constructor(args) {
        Object.defineProperty(this, "key", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "supply", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "maxSupply", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.key = MetadataKey.MasterEditionV2;
        this.supply = args.supply;
        this.maxSupply = args.maxSupply;
    }
}
exports.MasterEditionV2 = MasterEditionV2;
class EditionMarker {
    constructor(args) {
        Object.defineProperty(this, "key", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "ledger", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.key = MetadataKey.EditionMarker;
        this.ledger = args.ledger;
    }
    editionTaken(edition) {
        const editionOffset = edition % EDITION_MARKER_BIT_SIZE;
        const indexOffset = Math.floor(editionOffset / 8);
        if (indexOffset > 30) {
            throw Error('bad index for edition');
        }
        const positionInBitsetFromRight = 7 - (editionOffset % 8);
        const mask = Math.pow(2, positionInBitsetFromRight);
        const appliedMask = this.ledger[indexOffset] & mask;
        return appliedMask != 0;
    }
}
exports.EditionMarker = EditionMarker;
class Edition {
    constructor(args) {
        Object.defineProperty(this, "key", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        /// Points at MasterEdition struct
        Object.defineProperty(this, "parent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        /// Starting at 0 for master record, this is incremented for each edition minted.
        Object.defineProperty(this, "edition", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.key = MetadataKey.EditionV1;
        this.parent = args.parent;
        this.edition = args.edition;
    }
}
exports.Edition = Edition;
class Creator {
    constructor(args) {
        Object.defineProperty(this, "address", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "verified", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "share", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.address = args.address;
        this.verified = args.verified;
        this.share = args.share;
    }
}
exports.Creator = Creator;
class Data {
    constructor(args) {
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "symbol", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "uri", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sellerFeeBasisPoints", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "creators", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.name = args.name;
        this.symbol = args.symbol;
        this.uri = args.uri;
        this.sellerFeeBasisPoints = args.sellerFeeBasisPoints;
        this.creators = args.creators;
    }
}
exports.Data = Data;
class Metadata {
    constructor(args) {
        Object.defineProperty(this, "key", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "updateAuthority", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "mint", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "data", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "primarySaleHappened", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isMutable", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "editionNonce", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // set lazy
        Object.defineProperty(this, "masterEdition", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "edition", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.key = MetadataKey.MetadataV1;
        this.updateAuthority = args.updateAuthority;
        this.mint = args.mint;
        this.data = args.data;
        this.primarySaleHappened = args.primarySaleHappened;
        this.isMutable = args.isMutable;
        this.editionNonce = args.editionNonce;
    }
    init() {
        return __awaiter(this, void 0, void 0, function* () {
            const edition = yield getEdition(this.mint);
            this.edition = edition;
            this.masterEdition = edition;
        });
    }
}
exports.Metadata = Metadata;
class CreateMetadataArgs {
    constructor(args) {
        Object.defineProperty(this, "instruction", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "data", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isMutable", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.data = args.data;
        this.isMutable = args.isMutable;
    }
}
class UpdateMetadataArgs {
    constructor(args) {
        Object.defineProperty(this, "instruction", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 1
        });
        Object.defineProperty(this, "data", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        // Not used by this app, just required for instruction
        Object.defineProperty(this, "updateAuthority", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "primarySaleHappened", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.data = args.data ? args.data : null;
        this.updateAuthority = args.updateAuthority ? args.updateAuthority : null;
        this.primarySaleHappened = args.primarySaleHappened;
    }
}
class CreateMasterEditionArgs {
    constructor(args) {
        Object.defineProperty(this, "instruction", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 10
        });
        Object.defineProperty(this, "maxSupply", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.maxSupply = args.maxSupply;
    }
}
class MintPrintingTokensArgs {
    constructor(args) {
        Object.defineProperty(this, "instruction", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 9
        });
        Object.defineProperty(this, "supply", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.supply = args.supply;
    }
}
exports.METADATA_SCHEMA = new Map([
    [
        CreateMetadataArgs,
        {
            kind: 'struct',
            fields: [
                ['instruction', 'u8'],
                ['data', Data],
                ['isMutable', 'u8'], // bool
            ],
        },
    ],
    [
        UpdateMetadataArgs,
        {
            kind: 'struct',
            fields: [
                ['instruction', 'u8'],
                ['data', { kind: 'option', type: Data }],
                ['updateAuthority', { kind: 'option', type: 'pubkeyAsString' }],
                ['primarySaleHappened', { kind: 'option', type: 'u8' }],
            ],
        },
    ],
    [
        CreateMasterEditionArgs,
        {
            kind: 'struct',
            fields: [
                ['instruction', 'u8'],
                ['maxSupply', { kind: 'option', type: 'u64' }],
            ],
        },
    ],
    [
        MintPrintingTokensArgs,
        {
            kind: 'struct',
            fields: [
                ['instruction', 'u8'],
                ['supply', 'u64'],
            ],
        },
    ],
    [
        MasterEditionV1,
        {
            kind: 'struct',
            fields: [
                ['key', 'u8'],
                ['supply', 'u64'],
                ['maxSupply', { kind: 'option', type: 'u64' }],
                ['printingMint', 'pubkeyAsString'],
                ['oneTimePrintingAuthorizationMint', 'pubkeyAsString'],
            ],
        },
    ],
    [
        MasterEditionV2,
        {
            kind: 'struct',
            fields: [
                ['key', 'u8'],
                ['supply', 'u64'],
                ['maxSupply', { kind: 'option', type: 'u64' }],
            ],
        },
    ],
    [
        Edition,
        {
            kind: 'struct',
            fields: [
                ['key', 'u8'],
                ['parent', 'pubkeyAsString'],
                ['edition', 'u64'],
            ],
        },
    ],
    [
        Data,
        {
            kind: 'struct',
            fields: [
                ['name', 'string'],
                ['symbol', 'string'],
                ['uri', 'string'],
                ['sellerFeeBasisPoints', 'u16'],
                ['creators', { kind: 'option', type: [Creator] }],
            ],
        },
    ],
    [
        Creator,
        {
            kind: 'struct',
            fields: [
                ['address', 'pubkeyAsString'],
                ['verified', 'u8'],
                ['share', 'u8'],
            ],
        },
    ],
    [
        Metadata,
        {
            kind: 'struct',
            fields: [
                ['key', 'u8'],
                ['updateAuthority', 'pubkeyAsString'],
                ['mint', 'pubkeyAsString'],
                ['data', Data],
                ['primarySaleHappened', 'u8'],
                ['isMutable', 'u8'], // bool
            ],
        },
    ],
    [
        EditionMarker,
        {
            kind: 'struct',
            fields: [
                ['key', 'u8'],
                ['ledger', [31]],
            ],
        },
    ],
]);
const METADATA_REPLACE = new RegExp('\u0000', 'g');
const decodeMetadata = (buffer) => {
    const metadata = (0, borsh_1.deserializeUnchecked)(exports.METADATA_SCHEMA, Metadata, buffer);
    metadata.data.name = metadata.data.name.replace(METADATA_REPLACE, '');
    metadata.data.uri = metadata.data.uri.replace(METADATA_REPLACE, '');
    metadata.data.symbol = metadata.data.symbol.replace(METADATA_REPLACE, '');
    return metadata;
};
exports.decodeMetadata = decodeMetadata;
const decodeEditionMarker = (buffer) => {
    const editionMarker = (0, borsh_1.deserializeUnchecked)(exports.METADATA_SCHEMA, EditionMarker, buffer);
    return editionMarker;
};
exports.decodeEditionMarker = decodeEditionMarker;
const decodeEdition = (buffer) => {
    return (0, borsh_1.deserializeUnchecked)(exports.METADATA_SCHEMA, Edition, buffer);
};
exports.decodeEdition = decodeEdition;
const decodeMasterEdition = (buffer) => {
    if (buffer[0] == MetadataKey.MasterEditionV1) {
        return (0, borsh_1.deserializeUnchecked)(exports.METADATA_SCHEMA, MasterEditionV1, buffer);
    }
    else {
        return (0, borsh_1.deserializeUnchecked)(exports.METADATA_SCHEMA, MasterEditionV2, buffer);
    }
};
exports.decodeMasterEdition = decodeMasterEdition;
function updateMetadata(data, newUpdateAuthority, primarySaleHappened, mintKey, updateAuthority, instructions, metadataAccount) {
    return __awaiter(this, void 0, void 0, function* () {
        const metadataProgramId = (0, token_1.programIds)().metadata;
        metadataAccount =
            metadataAccount ||
                (yield (0, token_1.findProgramAddress)([
                    Buffer.from('metadata'),
                    (0, id_1.toPublicKey)(metadataProgramId).toBuffer(),
                    (0, id_1.toPublicKey)(mintKey).toBuffer(),
                ], (0, id_1.toPublicKey)(metadataProgramId)))[0];
        const value = new UpdateMetadataArgs({
            data,
            updateAuthority: !newUpdateAuthority ? undefined : newUpdateAuthority,
            primarySaleHappened: primarySaleHappened === null || primarySaleHappened === undefined
                ? null
                : primarySaleHappened,
        });
        const txnData = Buffer.from((0, borsh_1.serialize)(exports.METADATA_SCHEMA, value));
        const keys = [
            {
                pubkey: (0, id_1.toPublicKey)(metadataAccount),
                isSigner: false,
                isWritable: true,
            },
            {
                pubkey: (0, id_1.toPublicKey)(updateAuthority),
                isSigner: true,
                isWritable: false,
            },
        ];
        instructions.push(new web3_js_1.TransactionInstruction({
            keys,
            programId: (0, id_1.toPublicKey)(metadataProgramId),
            data: txnData,
        }));
        return metadataAccount;
    });
}
exports.updateMetadata = updateMetadata;
function createMetadata(data, updateAuthority, mintKey, mintAuthorityKey, instructions, payer) {
    return __awaiter(this, void 0, void 0, function* () {
        const metadataProgramId = (0, token_1.programIds)().metadata;
        const metadataAccount = (yield (0, token_1.findProgramAddress)([
            Buffer.from('metadata'),
            (0, id_1.toPublicKey)(metadataProgramId).toBuffer(),
            (0, id_1.toPublicKey)(mintKey).toBuffer(),
        ], (0, id_1.toPublicKey)(metadataProgramId)))[0];
        const value = new CreateMetadataArgs({ data, isMutable: true });
        const txnData = Buffer.from((0, borsh_1.serialize)(exports.METADATA_SCHEMA, value));
        const keys = [
            {
                pubkey: (0, id_1.toPublicKey)(metadataAccount),
                isSigner: false,
                isWritable: true,
            },
            {
                pubkey: (0, id_1.toPublicKey)(mintKey),
                isSigner: false,
                isWritable: false,
            },
            {
                pubkey: (0, id_1.toPublicKey)(mintAuthorityKey),
                isSigner: true,
                isWritable: false,
            },
            {
                pubkey: (0, id_1.toPublicKey)(payer),
                isSigner: true,
                isWritable: false,
            },
            {
                pubkey: (0, id_1.toPublicKey)(updateAuthority),
                isSigner: false,
                isWritable: false,
            },
            {
                pubkey: web3_js_1.SystemProgram.programId,
                isSigner: false,
                isWritable: false,
            },
            {
                pubkey: web3_js_1.SYSVAR_RENT_PUBKEY,
                isSigner: false,
                isWritable: false,
            },
        ];
        instructions.push(new web3_js_1.TransactionInstruction({
            keys,
            programId: (0, id_1.toPublicKey)(metadataProgramId),
            data: txnData,
        }));
        return metadataAccount;
    });
}
exports.createMetadata = createMetadata;
function createMasterEdition(maxSupply, mintKey, updateAuthorityKey, mintAuthorityKey, payer, instructions) {
    return __awaiter(this, void 0, void 0, function* () {
        const metadataProgramId = (0, token_1.programIds)().metadata;
        const metadataAccount = (yield (0, token_1.findProgramAddress)([
            Buffer.from(id_1.METADATA_PREFIX),
            (0, id_1.toPublicKey)(metadataProgramId).toBuffer(),
            (0, id_1.toPublicKey)(mintKey).toBuffer(),
        ], (0, id_1.toPublicKey)(metadataProgramId)))[0];
        const editionAccount = (yield (0, token_1.findProgramAddress)([
            Buffer.from(id_1.METADATA_PREFIX),
            (0, id_1.toPublicKey)(metadataProgramId).toBuffer(),
            (0, id_1.toPublicKey)(mintKey).toBuffer(),
            Buffer.from(EDITION),
        ], (0, id_1.toPublicKey)(metadataProgramId)))[0];
        const value = new CreateMasterEditionArgs({ maxSupply: maxSupply || null });
        const data = Buffer.from((0, borsh_1.serialize)(exports.METADATA_SCHEMA, value));
        const keys = [
            {
                pubkey: (0, id_1.toPublicKey)(editionAccount),
                isSigner: false,
                isWritable: true,
            },
            {
                pubkey: (0, id_1.toPublicKey)(mintKey),
                isSigner: false,
                isWritable: true,
            },
            {
                pubkey: (0, id_1.toPublicKey)(updateAuthorityKey),
                isSigner: true,
                isWritable: false,
            },
            {
                pubkey: (0, id_1.toPublicKey)(mintAuthorityKey),
                isSigner: true,
                isWritable: false,
            },
            {
                pubkey: (0, id_1.toPublicKey)(payer),
                isSigner: true,
                isWritable: false,
            },
            {
                pubkey: (0, id_1.toPublicKey)(metadataAccount),
                isSigner: false,
                isWritable: false,
            },
            {
                pubkey: (0, token_1.programIds)().token,
                isSigner: false,
                isWritable: false,
            },
            {
                pubkey: web3_js_1.SystemProgram.programId,
                isSigner: false,
                isWritable: false,
            },
            {
                pubkey: web3_js_1.SYSVAR_RENT_PUBKEY,
                isSigner: false,
                isWritable: false,
            },
        ];
        instructions.push(new web3_js_1.TransactionInstruction({
            keys,
            programId: (0, id_1.toPublicKey)(metadataProgramId),
            data,
        }));
    });
}
exports.createMasterEdition = createMasterEdition;
function deprecatedMintNewEditionFromMasterEditionViaPrintingToken(newMint, tokenMint, newMintAuthority, printingMint, authorizationTokenHoldingAccount, burnAuthority, updateAuthorityOfMaster, reservationList, instructions, payer) {
    return __awaiter(this, void 0, void 0, function* () {
        const metadataProgramId = (0, token_1.programIds)().metadata;
        const newMetadataKey = yield getMetadataAddress(newMint);
        const masterMetadataKey = yield getMetadataAddress(tokenMint);
        const newEdition = yield getEdition(newMint);
        const masterEdition = yield getEdition(tokenMint);
        const data = Buffer.from([3]);
        const keys = [
            {
                pubkey: (0, id_1.toPublicKey)(newMetadataKey),
                isSigner: false,
                isWritable: true,
            },
            {
                pubkey: (0, id_1.toPublicKey)(newEdition),
                isSigner: false,
                isWritable: true,
            },
            {
                pubkey: (0, id_1.toPublicKey)(masterEdition),
                isSigner: false,
                isWritable: true,
            },
            {
                pubkey: (0, id_1.toPublicKey)(newMint),
                isSigner: false,
                isWritable: true,
            },
            {
                pubkey: (0, id_1.toPublicKey)(newMintAuthority),
                isSigner: true,
                isWritable: false,
            },
            {
                pubkey: (0, id_1.toPublicKey)(printingMint),
                isSigner: false,
                isWritable: true,
            },
            {
                pubkey: (0, id_1.toPublicKey)(authorizationTokenHoldingAccount),
                isSigner: false,
                isWritable: true,
            },
            {
                pubkey: (0, id_1.toPublicKey)(burnAuthority),
                isSigner: true,
                isWritable: false,
            },
            {
                pubkey: (0, id_1.toPublicKey)(payer),
                isSigner: true,
                isWritable: false,
            },
            {
                pubkey: (0, id_1.toPublicKey)(updateAuthorityOfMaster),
                isSigner: false,
                isWritable: false,
            },
            {
                pubkey: (0, id_1.toPublicKey)(masterMetadataKey),
                isSigner: false,
                isWritable: false,
            },
            {
                pubkey: (0, token_1.programIds)().token,
                isSigner: false,
                isWritable: false,
            },
            {
                pubkey: web3_js_1.SystemProgram.programId,
                isSigner: false,
                isWritable: false,
            },
            {
                pubkey: web3_js_1.SYSVAR_RENT_PUBKEY,
                isSigner: false,
                isWritable: false,
            },
        ];
        if (reservationList) {
            keys.push({
                pubkey: (0, id_1.toPublicKey)(reservationList),
                isSigner: false,
                isWritable: true,
            });
        }
        instructions.push(new web3_js_1.TransactionInstruction({
            keys,
            programId: (0, id_1.toPublicKey)(metadataProgramId),
            data,
        }));
    });
}
exports.deprecatedMintNewEditionFromMasterEditionViaPrintingToken = deprecatedMintNewEditionFromMasterEditionViaPrintingToken;
function mintNewEditionFromMasterEditionViaToken(newMint, tokenMint, newMintAuthority, newUpdateAuthority, tokenOwner, tokenAccount, instructions, payer, edition) {
    return __awaiter(this, void 0, void 0, function* () {
        const metadataProgramId = (0, token_1.programIds)().metadata;
        const newMetadataKey = yield getMetadataAddress(newMint);
        const masterMetadataKey = yield getMetadataAddress(tokenMint);
        const newEdition = yield getEdition(newMint);
        const masterEdition = yield getEdition(tokenMint);
        const editionMarkPda = yield getEditionMarkPda(tokenMint, edition);
        const data = Buffer.from([11, ...edition.toArray('le', 8)]);
        const keys = [
            {
                pubkey: (0, id_1.toPublicKey)(newMetadataKey),
                isSigner: false,
                isWritable: true,
            },
            {
                pubkey: (0, id_1.toPublicKey)(newEdition),
                isSigner: false,
                isWritable: true,
            },
            {
                pubkey: (0, id_1.toPublicKey)(masterEdition),
                isSigner: false,
                isWritable: true,
            },
            {
                pubkey: (0, id_1.toPublicKey)(newMint),
                isSigner: false,
                isWritable: true,
            },
            {
                pubkey: (0, id_1.toPublicKey)(editionMarkPda),
                isSigner: false,
                isWritable: true,
            },
            {
                pubkey: (0, id_1.toPublicKey)(newMintAuthority),
                isSigner: true,
                isWritable: false,
            },
            {
                pubkey: (0, id_1.toPublicKey)(payer),
                isSigner: true,
                isWritable: false,
            },
            {
                pubkey: (0, id_1.toPublicKey)(tokenOwner),
                isSigner: true,
                isWritable: false,
            },
            {
                pubkey: (0, id_1.toPublicKey)(tokenAccount),
                isSigner: false,
                isWritable: false,
            },
            {
                pubkey: (0, id_1.toPublicKey)(newUpdateAuthority),
                isSigner: false,
                isWritable: false,
            },
            {
                pubkey: (0, id_1.toPublicKey)(masterMetadataKey),
                isSigner: false,
                isWritable: false,
            },
            {
                pubkey: (0, token_1.programIds)().token,
                isSigner: false,
                isWritable: false,
            },
            {
                pubkey: web3_js_1.SystemProgram.programId,
                isSigner: false,
                isWritable: false,
            },
            {
                pubkey: web3_js_1.SYSVAR_RENT_PUBKEY,
                isSigner: false,
                isWritable: false,
            },
        ];
        instructions.push(new web3_js_1.TransactionInstruction({
            keys,
            programId: (0, id_1.toPublicKey)(metadataProgramId),
            data,
        }));
    });
}
exports.mintNewEditionFromMasterEditionViaToken = mintNewEditionFromMasterEditionViaToken;
function getEdition(tokenMint) {
    return __awaiter(this, void 0, void 0, function* () {
        const PROGRAM_IDS = (0, token_1.programIds)();
        return (yield (0, token_1.findProgramAddress)([
            Buffer.from(id_1.METADATA_PREFIX),
            (0, id_1.toPublicKey)(PROGRAM_IDS.metadata).toBuffer(),
            (0, id_1.toPublicKey)(tokenMint).toBuffer(),
            Buffer.from(EDITION),
        ], (0, id_1.toPublicKey)(PROGRAM_IDS.metadata)))[0];
    });
}
exports.getEdition = getEdition;
/**
 * Derive metadata program derived account address
 * for a token mint address
 */
function getMetadataAddress(tokenMint) {
    return __awaiter(this, void 0, void 0, function* () {
        const PROGRAM_IDS = (0, token_1.programIds)();
        return (yield (0, token_1.findProgramAddress)([
            Buffer.from(id_1.METADATA_PREFIX),
            (0, id_1.toPublicKey)(PROGRAM_IDS.metadata).toBuffer(),
            (0, id_1.toPublicKey)(tokenMint).toBuffer(),
        ], (0, id_1.toPublicKey)(PROGRAM_IDS.metadata)))[0];
    });
}
exports.getMetadataAddress = getMetadataAddress;
/**
 * Fetch on-chain metadata for a token mint address
 */
function getMetadata(connection, tokenMint) {
    return __awaiter(this, void 0, void 0, function* () {
        const metadataPda = yield getMetadataAddress(tokenMint);
        const accountInfo = yield connection.getAccountInfo(new web3_js_1.PublicKey(metadataPda));
        if (!accountInfo) {
            return null;
        }
        return (0, exports.decodeMetadata)(accountInfo.data);
    });
}
exports.getMetadata = getMetadata;
function deprecatedGetReservationList(masterEdition, resource) {
    return __awaiter(this, void 0, void 0, function* () {
        const PROGRAM_IDS = (0, token_1.programIds)();
        return (yield (0, token_1.findProgramAddress)([
            Buffer.from(id_1.METADATA_PREFIX),
            (0, id_1.toPublicKey)(PROGRAM_IDS.metadata).toBuffer(),
            (0, id_1.toPublicKey)(masterEdition).toBuffer(),
            Buffer.from(RESERVATION),
            (0, id_1.toPublicKey)(resource).toBuffer(),
        ], (0, id_1.toPublicKey)(PROGRAM_IDS.metadata)))[0];
    });
}
exports.deprecatedGetReservationList = deprecatedGetReservationList;
function getEditionMarkPda(mint, edition) {
    return __awaiter(this, void 0, void 0, function* () {
        const PROGRAM_IDS = (0, token_1.programIds)();
        const editionNumber = Math.floor(edition.toNumber() / 248);
        return (yield (0, token_1.findProgramAddress)([
            Buffer.from(id_1.METADATA_PREFIX),
            (0, id_1.toPublicKey)(PROGRAM_IDS.metadata).toBuffer(),
            (0, id_1.toPublicKey)(mint).toBuffer(),
            Buffer.from(EDITION),
            Buffer.from(editionNumber.toString()),
        ], (0, id_1.toPublicKey)(PROGRAM_IDS.metadata)))[0];
    });
}
exports.getEditionMarkPda = getEditionMarkPda;
