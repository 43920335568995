"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldUseCdn = exports.isVideo = exports.buildCdnUrl = exports.ImageSize = void 0;
// size in px
exports.ImageSize = Object.freeze({
    tiny: '50',
    extraSmall: '100',
    small: '250',
    medium: '400',
    large: '640',
});
const CDN_BASE_URL = 'https://img-cdn.magiceden.dev';
const buildCdnUrl = (url, opts) => {
    var _a, _b, _c;
    const rsOpts = (_a = opts.rs) !== null && _a !== void 0 ? _a : 'fill';
    const keepNftstoragOpts = (_b = opts.keepNftstorageBaseUrl) !== null && _b !== void 0 ? _b : false;
    const sizeX = exports.ImageSize[opts.sizeX];
    const sizeY = exports.ImageSize[(_c = opts.sizeY) !== null && _c !== void 0 ? _c : opts.sizeX];
    if (url.includes(CDN_BASE_URL)) {
        // If url is already a CDN url, don't build again
        return url;
    }
    // only encode if there are query params
    const escaped = url.includes('?') ? encodeURIComponent(url) : url;
    let cdnUrl = `${CDN_BASE_URL}/rs:${rsOpts}:${sizeX}:${sizeY}:0:0/plain/${keepNftstoragOpts
        ? escaped
        : escaped.replace('nftstorage.link', 'dweb.link')}`;
    if (opts.renderGifThumbnail && isGif(url, opts.mimeType)) {
        cdnUrl += '@png';
    }
    return cdnUrl;
};
exports.buildCdnUrl = buildCdnUrl;
function isGif(url, mimeType) {
    return url.endsWith('gif') || Boolean(mimeType === null || mimeType === void 0 ? void 0 : mimeType.includes('gif'));
}
function isWebp(url) {
    return url.endsWith('webp');
}
function isDataUri(url) {
    return url.startsWith('data:');
}
function isVideo(url) {
    return url.endsWith('mp4');
}
exports.isVideo = isVideo;
function shouldMimeTypeUseCDN(mimeType) {
    return !['gif', 'video'].some(type => mimeType.includes(type));
}
function shouldUseCdn({ img, disableCdn, mimeType, renderGifThumbnail, }) {
    if (disableCdn) {
        return false;
    }
    if (mimeType && !shouldMimeTypeUseCDN(mimeType)) {
        return false;
    }
    // If renderGifThumbnail is set, use CDN to render the thumbnail
    if (isGif(img, mimeType) && renderGifThumbnail) {
        return true;
    }
    return (!isWebp(img) && !isGif(img, mimeType) && !isVideo(img) && !isDataUri(img));
}
exports.shouldUseCdn = shouldUseCdn;
