import { getHttpClient } from 'src/queries/http-client';

export async function checkSymbolUniqueness({
  authorization,
  symbol,
  draftId,
}: {
  authorization: any;
  symbol: string;
  draftId: string;
}) {
  // const { authorization } = useAuth();
  const headers: Record<string, string> = {};
  if (authorization) {
    headers.authorization = authorization;
  }

  const value = symbol.trim();
  if (value.length === 0) {
    return;
  }

  const res = await getHttpClient({ headers }).post(
    `/api/admin/collection-drafts-uniqueness`,
    {
      draftId,
      symbol,
    },
  );
  return res.data;
}
