import { IAdminCollectionDraft } from '@ma/shared/creators';
import { Anchor, Button, Center, Group } from '@mantine/core';
import { TokenStandard } from '@metaplex-foundation/mpl-token-metadata';
import * as dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjsUtcPlugin from 'dayjs/plugin/utc';
import _map from 'lodash/map';
import { useMemo } from 'react';
import {
  SOURCE_LABEL,
  TOKEN_STANDARD_LABEL,
} from '../../entities/collection-draft';
import { MeTable, MeTableCell, MeTableColum } from 'src/components/MeTable';
import { MeImage } from 'src/components/MeImage';
import { BlockchainIcon, TokenStandardIcon } from 'src/icons';
import { TbPhotoOff } from 'react-icons/tb';

type CollectionDraftsTableProps = {
  collectionDrafts: IAdminCollectionDraft[];
  onEditClick: (id: string) => void;
  onGradeClick: (id: string) => void;
};

/**
 * FIXME: exptract and copies of types and functions from frontend package
 */
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(dayjsUtcPlugin);

const EMPTY_VALUE_STUB = '--';

export const formatDateTs = (
  date: Date | string | undefined | null,
): string => {
  if (!date) return EMPTY_VALUE_STUB;

  try {
    return dayjs.utc(date).format('YYYY-MM-DD HH:mm:ss UTC');
  } catch (_err: unknown) {
    return EMPTY_VALUE_STUB;
  }
};

export enum TokenStandardValues {
  NFT = TokenStandard.NonFungible,
  SFT = TokenStandard.FungibleAsset,
  SPL = TokenStandard.Fungible,
  NFT_EDITION = TokenStandard.NonFungibleEdition,
}

// end FIXME

export const CollectionDraftsTable = (props: CollectionDraftsTableProps) => {
  const { onEditClick, onGradeClick } = props;
  const columns: MeTableColum<IAdminCollectionDraft>[] = useMemo(
    () => [
      {
        accessor: 'actions',
        Cell(cellProps: MeTableCell<IAdminCollectionDraft>) {
          return (
            <Group>
              <Button
                compact
                variant="subtle"
                onClick={() => onGradeClick(cellProps.row._id)}
              >
                Actions
              </Button>
              <Button
                compact
                variant="subtle"
                onClick={() => onEditClick(cellProps.row._id)}
              >
                Edit
              </Button>
            </Group>
          );
        },
      },
      {
        accessor: 'profileImage',
        Header: 'PFP',
        Cell: (cellProps: MeTableCell<IAdminCollectionDraft>) => {
          if (!cellProps.row.assets?.profileImage) {
            return (
              <Center>
                <TbPhotoOff size={35} />
              </Center>
            );
          }

          return (
            <div>
              <MeImage src={cellProps.row.assets?.profileImage} size="tiny" />
            </div>
          );
        },
      },
      {
        accessor: '_chain_standard',
        Header: 'Chain/Standard',
        Cell: (cellProps: MeTableCell<IAdminCollectionDraft>) => {
          const chain = cellProps.row.blockchain || 'unknown';
          if (cellProps.row.tokenStandard) {
            const tokenStandard =
              TOKEN_STANDARD_LABEL[cellProps.row.tokenStandard];
            return (
              <span>
                <BlockchainIcon chain={chain} />/
                <TokenStandardIcon standard={tokenStandard} />
              </span>
            );
          }
          return (
            <span>
              <BlockchainIcon chain={chain} />/
              <TokenStandardIcon standard="unknown" />
            </span>
          );
        },
      },
      {
        accessor: 'symbol',
        Header: 'Symbol',
      },
      {
        accessor: 'name',
        Header: 'Name',
      },
      {
        accessor: 'similarMints.maxScore',
        Header: 'Score',
      },
      {
        accessor: 'submissionStatus',
        Header: 'Status',
      },
      {
        accessor: 'source',
        Header: 'Source',
        Cell: cellProps => {
          if (!cellProps.row.source || !cellProps.row.source.length) {
            return SOURCE_LABEL[''];
          }
          return _map(cellProps.row.source, key => SOURCE_LABEL[key]);
        },
      },
      {
        accessor: 'totalSupply',
        Header: 'Total Supply',
      },
      {
        accessor: 'stats.uniqueMintersCount',
        Header: 'Unique Minters',
        Cell(cellProps: MeTableCell<IAdminCollectionDraft>) {
          return (
            <span>{cellProps.row?.stats?.uniqueMintersCount || '--'}</span>
          );
        },
      },
      {
        accessor: 'mintDate',
        Header: 'Mint date (UTC)',
        Cell(cellProps: MeTableCell<IAdminCollectionDraft>) {
          return <span>{formatDateTs(cellProps.row?.mintDate)}</span>;
        },
      },
      {
        accessor: 'listed',
        Header: 'Listed',
      },
      {
        accessor: 'derivative.originalLink',
        Header: 'Derivative',
        Cell(cellProps: MeTableCell<IAdminCollectionDraft>) {
          return (
            <span>
              {cellProps.row?.derivative?.originalLink ? 'yes' : 'no'}
            </span>
          );
        },
      },
      {
        accessor: 'isAutolist',
        Header: 'Autolisted',
        Cell(cellProps: MeTableCell<IAdminCollectionDraft>) {
          return <span>{cellProps.row?.isAutolist ? 'yes' : 'no'}</span>;
        },
      },
      {
        accessor: 'author',
        Header: 'Email',
      },
      {
        accessor: 'links.twitter',
        Header: 'Twitter',
        Cell(cellProps: MeTableCell<IAdminCollectionDraft>) {
          if (!cellProps.row?.links?.twitter) {
            return EMPTY_VALUE_STUB;
          }
          return (
            <Anchor
              target="_blank"
              rel="noreferrer"
              href={`https://twitter.com/${cellProps.row.links.twitter}`}
            >
              {cellProps.row.links.twitter}
            </Anchor>
          );
        },
      },
      {
        accessor: 'updatedAt',
        Header: 'Updated',
      },
    ],
    [onEditClick, onGradeClick],
  );
  return (
    <MeTable
      columns={columns}
      data={props.collectionDrafts}
      verticalSpacing={1}
    />
  );
};
