import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { getHttpClient, handleHttpClientError } from 'src/queries/http-client';
import { useAuth } from 'src/hooks';

export const useCreateBlacklistMutation = (
  opts: UseMutationOptions<
    { data: { _id: string; accountType: string; value: string } },
    AxiosError,
    { body: { accountType: string; value: string } }
  > = {},
) => {
  const { authorization } = useAuth();
  const headers: Record<string, string> = {};
  if (authorization) {
    headers.authorization = authorization;
  }

  const createBlacklist = async (variables: {
    body: { accountType: string; value: string };
  }) => {
    const res = await getHttpClient({ headers }).post<{
      data: { _id: string; accountType: string; value: string };
    }>('/api/admin/blacklist/new', variables.body);
    return res.data;
  };

  return useMutation(createBlacklist, {
    onError: handleHttpClientError,
    ...opts,
  });
};

export const useBulkDeleteBlacklistMutation = (
  opts: UseMutationOptions<
    { data: { deleted: number } },
    AxiosError,
    { body: { ids: string[] } }
  > = {},
) => {
  const { authorization } = useAuth();
  const headers: Record<string, string> = {};
  if (authorization) {
    headers.authorization = authorization;
  }

  const bulkDeleteBlacklist = async (variables: {
    body: { ids: string[] };
  }) => {
    const res = await getHttpClient({ headers }).post<{
      data: { deleted: number };
    }>('/api/admin/blacklist/bulk-delete', variables.body);
    return res.data;
  };

  return useMutation(bulkDeleteBlacklist, {
    onError: handleHttpClientError,
    ...opts,
  });
};
