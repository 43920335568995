"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidUrl = exports.sanitizeUrl = void 0;
// Taken from angular sanitization util
// https://pragmaticwebsecurity.com/articles/spasecurity/react-xss-part1.html
const SAFE_URL_PATTERN = /^(?:(?:https?|mailto|ftp|tel|file|sms):|[^&:/?#]*(?:[/?#]|$))/gi;
/** A pattern that matches safe data URLs. It only matches image, video, and audio types. */
const DATA_URL_PATTERN = /^data:(?:image\/(?:bmp|gif|jpeg|jpg|png|tiff|webp)|video\/(?:mpeg|mp4|ogg|webm)|audio\/(?:mp3|oga|ogg|opus));base64,[a-z0-9+\/]+=*$/i;
function _sanitizeUrl(url) {
    url = String(url);
    if (url === 'null' || url.length === 0 || url === 'about:blank')
        return 'about:blank';
    if (url.match(SAFE_URL_PATTERN) || url.match(DATA_URL_PATTERN))
        return url;
    return `unsafe:${url}`;
}
function sanitizeUrl(url = 'about:blank') {
    return _sanitizeUrl(String(url).trim());
}
exports.sanitizeUrl = sanitizeUrl;
const isValidUrl = (str) => {
    let url;
    try {
        url = new URL(str);
    }
    catch (err) {
        return false;
    }
    return url.protocol === 'https:' || url.protocol === 'http:';
};
exports.isValidUrl = isValidUrl;
