import { IReviewRequest } from '@ma/shared/admin';
import { ReviewRequest } from './ReviewRequest';
import { Flex } from '@mantine/core';
import { useReviewRequestResolutionMutation } from 'src/mutations/review-requests';
import notifications from 'src/utils/notifications';
import { handleHttpClientError } from '@ma/shared/util/httpClient';
import { AnalyticEvent, analytics } from 'src/utils/analytics';

type ReviewPipelineProps = {
  reviewRequests: IReviewRequest[];
  refetch: () => void;
};

export const ReviewPipeline = (props: ReviewPipelineProps) => {
  const reviewRequestResolutionMutation = useReviewRequestResolutionMutation({
    onSuccess: (resPayload, variables) => {
      let eventName: AnalyticEvent | null = null;
      switch (resPayload.data.status) {
        case 'approved':
          eventName = AnalyticEvent.CREATOR_ANNOUNCEMENT_APPROVED;
          break;
        case 'rejected':
          eventName = AnalyticEvent.CREATOR_ANNOUNCEMENT_REJECTED;
          break;
        case 'archived':
          eventName = AnalyticEvent.CREATOR_ANNOUNCEMENT_ARCHIVED;
          break;
      }
      if (eventName) {
        analytics.trackAggregate({
          eventName,
          anouncement_draft_id: resPayload.data._id,
          collection_symbol: variables.collectionId ?? 'unknown',
        });
      }
      notifications.success('resolution was received');
      props.refetch();
    },
    onError: err => {
      const [_status, _errCode, _errMsg, resMsg] = handleHttpClientError(err);
      notifications.error(
        resMsg || 'something went wrong, report to creator hub engineers',
      );
    },
  });

  return (
    <Flex direction="column" gap="1rem">
      {props.reviewRequests.map(item => (
        <ReviewRequest
          key={item.createdAt.toString()}
          request={item}
          onSubmit={reviewRequestResolutionMutation.mutate}
          isLoading={reviewRequestResolutionMutation.isLoading}
        />
      ))}
    </Flex>
  );
};
