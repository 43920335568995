"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserPresaleUpdateSchema = exports.BlacklistCreateSchema = exports.CollectionDraftGradeSchema = exports.CollectionDraftUpdateSchema = exports.CollectionUpdateSchema = exports.CollectionCreateSchema = void 0;
const v = __importStar(require("superstruct"));
const creators_1 = require("../creators");
const util_1 = require("../util");
const blacklist_1 = require("./blacklist");
exports.CollectionCreateSchema = v.object({
    name: util_1.collectionName,
    symbol: util_1.collectionSymbol,
    description: v.size(v.string(), 10, 1000),
    image: v.string(),
    nftImageType: v.optional(v.enums(['jpeg', 'gif', 'mp4'])),
    website: v.optional(v.string()),
    discord: v.optional(v.string()),
    twitter: v.optional(v.string()),
    categories: v.array(v.string()),
    sortBy: v.optional(v.string()),
    isDraft: v.boolean(),
    totalItems: v.number(),
});
exports.CollectionUpdateSchema = v.object({
    // update fields
    isVerified: v.defaulted(v.boolean(), false),
    nftImageType: v.optional(v.enums(['jpeg', 'gif', 'mp4'])),
    enabledAttributesFilters: v.defaulted(v.boolean(), false),
    enabledVersionFilter: v.defaulted(v.boolean(), false),
    enabledTotalSupply: v.defaulted(v.boolean(), false),
    enabledUniqueOwners: v.defaulted(v.boolean(), false),
    rarity: v.optional(v.object({
        showMoonrank: v.boolean(),
        showHowrare: v.boolean(),
        showMagicEden: v.boolean(),
    })),
    iframe: v.optional(v.string()),
    blockedMints: v.defaulted(v.string(), '[]'),
    blackListAttributes: v.defaulted(v.string(), '[]'),
});
exports.CollectionDraftUpdateSchema = v.object({
    // update flags
    shouldUpdateCollection: v.boolean(),
    // update fields
    name: util_1.collectionName,
    symbol: util_1.collectionSymbol,
    description: v.size(v.string(), 10, 1000),
    assets: v.object({
        profileImage: v.string(),
    }),
    isVerified: v.optional(v.boolean()),
    derivative: v.nullable(v.optional(v.object({
        originalLink: (0, util_1.notEmpty)(v.string()),
        originalName: v.size(v.string(), 3, 128),
    }))),
    categories: v.object({
        primary: v.enums(Object.values(creators_1.CollectionCategories)),
        secondary: v.nullable(v.optional(v.enums(Object.values(creators_1.CollectionCategories)))),
    }),
    links: v.object({
        twitter: v.optional(v.string()),
        discord: v.optional(v.string()),
        website: v.optional(v.string()),
        mint: v.optional(v.string()),
    }),
    permission: v.optional(v.enums(['author', 'licensed'])),
    applyPurpose: v.enums(Object.values(creators_1.ApplyPurposes)),
    totalSupply: (0, util_1.zeroOrPositive)(v.integer()),
    // mintDate: v.optional(v.nullable(isodate(v.string()))),
    mintDate: v.optional(v.nullable((0, util_1.isodateString)())),
    // TODO: find a way to validate form textarea import with array[] struct
    mint: v.defaulted(v.string(), '[]'),
    candyMachineIds: v.defaulted(v.string(), '[]'),
    rarity: v.optional(v.object({
        showMoonrank: v.boolean(),
        showHowrare: v.boolean(),
        showMagicEden: v.boolean(),
    })),
    creatorTipsAddress: v.optional(v.nullable(v.string())),
});
exports.CollectionDraftGradeSchema = v.object({
    id: v.string(),
    reviewMsg: v.string(),
    type: v.enums(['reject', 'approve', 'list']),
    collectionDraftPayload: v.object({
        source: v.array(v.string()),
    }),
    // last fetched version of the draft
    // used to prevent any actions on a stale draft data
    version: v.number(),
});
exports.BlacklistCreateSchema = v.object({
    accountType: v.enums(Object.values(blacklist_1.BlacklistAccountType)),
    value: v.string(),
});
exports.UserPresaleUpdateSchema = v.object({
    enable: v.boolean(),
});
