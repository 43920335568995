import { Sources } from '@ma/shared/creators';
import { TokenStandard } from '@metaplex-foundation/mpl-token-metadata';

export const SOURCE_LABEL = Object.freeze({
  [Sources.NONE]: 'None',
  [Sources.UNKNOWN]: 'Unknown',
  [Sources.CM_V2]: 'CandyMachine V2',
  [Sources.LAUNCHPAD]: 'ME Launchpad',
  [Sources.LMNFT]: 'LMNFT',
  [Sources.MONKELABS]: 'MonkeLabs',
  [Sources.NOVALAUNCH]: 'Nova Launch',
  [Sources.OPENSEA]: 'OpenSea',
});

export const SOURCE_OPTIONS = Object.freeze([
  { value: Sources.NONE, label: SOURCE_LABEL[Sources.NONE] },
  { value: Sources.UNKNOWN, label: SOURCE_LABEL[Sources.UNKNOWN] },
  {
    value: Sources.CM_V2,
    label: SOURCE_LABEL[Sources.CM_V2],
  },
  {
    value: Sources.LAUNCHPAD,
    label: SOURCE_LABEL[Sources.LAUNCHPAD],
  },
  {
    value: Sources.LMNFT,
    label: SOURCE_LABEL[Sources.LMNFT],
  },
  {
    value: Sources.MONKELABS,
    label: SOURCE_LABEL[Sources.MONKELABS],
  },
  {
    value: Sources.NOVALAUNCH,
    label: SOURCE_LABEL[Sources.NOVALAUNCH],
  },
  {
    value: Sources.OPENSEA,
    label: SOURCE_LABEL[Sources.OPENSEA],
  },
]);

export const TOKEN_STANDARD_LABEL = Object.freeze({
  [TokenStandard.NonFungible]: 'NFT',
  [TokenStandard.FungibleAsset]: 'SFT',
  [TokenStandard.Fungible]: 'SPL',
  [TokenStandard.NonFungibleEdition]: 'NFT edition',
} as const);
