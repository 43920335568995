import { showNotification as notification } from '@mantine/notifications';

/**
 * Admin Hub notification system
 *
 * depends on @mantine/notifications and NotificationsProvider context
 */

const info = (message: string) => {
  notification({ message, color: 'default' });
};

const success = (message: string) => {
  notification({ message, color: 'green' });
};

const warning = (message: string) => {
  notification({ message, color: 'orange' });
};

const error = (message: string) => {
  notification({ message, color: 'red' });
};

export default {
  info,
  success,
  warning,
  error,
};
