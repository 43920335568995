import { BlacklistAccountType, BlacklistCreateSchema } from '@ma/shared/admin';
import { Button, Drawer, Select, TextInput } from '@mantine/core';
import { useForm, UseFormReturnType } from '@mantine/form';
import { structForm } from 'src/utils/superstruct-form-validation';

type BlacklistCreateFormProps = {
  form: UseFormReturnType<any>;
  onSubmit: (
    variables: {
      body: {
        accountType: BlacklistAccountType;
        value: string;
      };
    },
    opts: { onSuccess: () => void },
  ) => void;
  isLoading: boolean;
};

const BlacklistCreateForm = (props: BlacklistCreateFormProps) => {
  return (
    <form
      onSubmit={props.form.onSubmit(formValues => {
        props.onSubmit(
          { body: formValues },
          {
            onSuccess: () => {
              props.form.reset();
            },
          },
        );
      })}
    >
      <Select
        required
        label="Type of Value"
        clearable
        data={Object.values(BlacklistAccountType)}
        {...props.form.getInputProps('accountType')}
      />
      <TextInput
        required
        label="Value to block"
        {...props.form.getInputProps('value')}
      />
      <Button type="submit" loading={props.isLoading}>
        Add To The Blacklist
      </Button>
    </form>
  );
};

type BlacklistCreateEditorProps = {
  opened: boolean;
  onSubmit: (
    variables: {
      body: {
        accountType: BlacklistAccountType;
        value: string;
      };
    },
    opts: { onSuccess: () => void },
  ) => void;
  onClose: () => void;
  loading: boolean;
};

export const BlacklistCreateEditor = (props: BlacklistCreateEditorProps) => {
  const createForm = useForm<any>(structForm({}, BlacklistCreateSchema));

  return (
    <Drawer
      opened={props.opened}
      onClose={() => {
        if (!createForm.isDirty() || confirm('discard editing?')) {
          createForm.reset();
          props.onClose();
        }
      }}
      title="Add a New Blacklist Record"
      padding="lg"
      size="600px"
      styles={{ drawer: { overflowY: 'scroll' } }}
    >
      <BlacklistCreateForm
        form={createForm}
        onSubmit={props.onSubmit}
        isLoading={props.loading}
      />
    </Drawer>
  );
};
