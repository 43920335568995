import {
  CollectionCreatePayload,
  CollectionUpdatePayload,
} from '@ma/shared/admin';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { getHttpClient, handleHttpClientError } from 'src/queries/http-client';
import { useAuth } from 'src/hooks';

export const useCreateCollectionMutation = (
  opts: UseMutationOptions<
    { ok: true; msg: string },
    AxiosError,
    { body: CollectionCreatePayload }
  >,
) => {
  const { authorization } = useAuth();
  const headers: Record<string, string> = {};
  if (authorization) {
    headers.authorization = authorization;
  }

  const createCollection = async (variables: {
    body: CollectionCreatePayload;
  }) => {
    const res = await getHttpClient({
      headers,
    }).post<{ ok: true; msg: string }>(
      '/api/admin/collections',
      variables.body,
    );
    return res.data;
  };

  return useMutation(createCollection, {
    onError: handleHttpClientError,
    ...opts,
  });
};

export const useUpdateCollectionMutation = (
  opts: UseMutationOptions<
    { ok: true; msg: string },
    AxiosError,
    { draftId: string; body: CollectionUpdatePayload }
  >,
) => {
  const { authorization } = useAuth();
  const headers: Record<string, string> = {};
  if (authorization) {
    headers.authorization = authorization;
  }

  const updateCollection = async (variables: {
    draftId: string;
    body: CollectionUpdatePayload;
  }) => {
    const res = await getHttpClient({ headers }).patch<{
      ok: true;
      msg: string;
    }>(
      `/api/admin/collection-drafts/${variables.draftId}/collection`,
      variables.body,
    );
    return res.data;
  };

  return useMutation(updateCollection, opts);
};
