"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MAX_CONTENT_CHARS = exports.MAX_TITLE_CHARS = exports.DEFAUL_LIVE_DAYS = exports.ANNOUNCEMENT_DRAFT_LIMIT = void 0;
/** maximum announcement draftss that creators can create in the interval of 30 days */
exports.ANNOUNCEMENT_DRAFT_LIMIT = Object.freeze({
    INTERVAL_DAYS: 30,
    MAX_NUMBER: 20,
});
/** number of days announcement will be live on the collection page */
exports.DEFAUL_LIVE_DAYS = 1;
exports.MAX_TITLE_CHARS = 90;
exports.MAX_CONTENT_CHARS = 700;
