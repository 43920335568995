import {
  ICollectionDraftResponse,
  IMeCollection,
  SubmissionStatus,
} from '@ma/shared/creators';
import { Badge } from '@mantine/core';

type CollectionBadgesProps = {
  draft: ICollectionDraftResponse | null | undefined;
  collection: IMeCollection | null | undefined;
};

const getColorFromDraft = (draft: ICollectionDraftResponse): string => {
  switch (draft.submissionStatus) {
    case 'listed':
    case 'approved':
      return 'violet';
    case 'rejected':
      return 'orange';
    case 'review':
      return 'gray';
    default:
      return 'gray';
  }
};

export const CollectionBadges = (props: CollectionBadgesProps) => {
  const badges: any = [];

  if (props.draft) {
    badges.push(
      <Badge key="submissionStatus" color={getColorFromDraft(props.draft)}>
        status{' '}
        {props.draft.submissionStatus === SubmissionStatus.LISTED
          ? SubmissionStatus.APPROVED
          : props.draft.submissionStatus}
      </Badge>,
    );
  }

  if (props.collection) {
    badges.push(
      <Badge key="listed" color="grape">
        listed
      </Badge>,
    );
    if (props.collection.deletedAt) {
      badges.push(
        <Badge key="soft-deleted" color="red">
          soft deleted
        </Badge>,
      );
    }
  } else if (props.draft?.listed) {
    // marked as liste in CH but has no collecrion data
    badges.push(
      <Badge key="listed" color="gray">
        marked as listed, but cannot get collection data
      </Badge>,
    );
  } else {
    badges.push(
      <Badge key="listed" color="gray">
        not listed
      </Badge>,
    );
  }

  return badges;
};
