"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findSubKInscriptions = exports.validateInscription = exports.VALID_BITCOIN_ADDRESS_REGEX = exports.VALID_INSCRIPTION_ID_REGEX = exports.validateInscriptions = void 0;
const ord_10k_1 = require("./ord-10k");
// Function to validate user input against the Inscription[] type
function validateInscriptions(input) {
    if (!input || !Array.isArray(input)) {
        throw new Error('[validateInscriptions] Inscriptions list is not a valid array');
    }
    // Check for duplicate token ids
    const duplicates = findDuplicates(input);
    if (duplicates.length > 0) {
        throw new Error(`Found duplicates: ${duplicates.toString()}`);
    }
    // Check if each object in `input` is valid Inscription type
    if (input.length > 0) {
        for (const inscription of input) {
            try {
                validateInscription(inscription);
            }
            catch (e) {
                throw new Error(`For inscription ${inscription.id}: ${e}`);
            }
        }
    }
    return true;
}
exports.validateInscriptions = validateInscriptions;
exports.VALID_INSCRIPTION_ID_REGEX = /^[0-9a-f]{64}i\d+$/;
exports.VALID_BITCOIN_ADDRESS_REGEX = /^(bc1|tb1|[23])[a-zA-HJ-NP-Z0-9]{25,90}$/;
// Function to validate user input against the Inscription type
function validateInscription(input) {
    if (typeof input !== 'object' || input === null) {
        throw new Error(`Not a valid object`);
    }
    // Check for the presence of the 'id' property
    if (typeof input.id !== 'string') {
        throw new Error(`Property "id" is not valid string type: "${input.id}"`);
    }
    else if (!exports.VALID_INSCRIPTION_ID_REGEX.test(input.id)) {
        throw new Error(`Property "id" is not valid for value "${input.id}"`);
    }
    // Check for the presence of the 'meta' property
    if (input.meta !== undefined) {
        try {
            validateInscriptionMeta(input.meta);
        }
        catch (e) {
            throw e;
        }
    }
    return true;
}
exports.validateInscription = validateInscription;
// Function to validate the InscriptionMeta type
function validateInscriptionMeta(meta) {
    if (typeof meta !== 'object' || meta === null) {
        throw new Error(`Property "meta" is not a valid object: ${meta}`);
    }
    // Check for the presence of the 'name' property
    if (typeof meta.name !== 'string') {
        throw new Error(`Property "meta.name" is not valid string type: ${meta.name}`);
    }
    // Check for the presence of the 'high_res_img_url'/'collection_page_img_url' property, if it exists
    if (meta.high_res_img_url !== undefined &&
        typeof meta.high_res_img_url !== 'string') {
        throw new Error(`Property "meta.high_res_img_url" is not valid string type: ${meta.high_res_img_url}`);
    }
    if (meta.collection_page_img_url !== undefined &&
        typeof meta.collection_page_img_url !== 'string') {
        throw new Error(`Property "meta.collection_page_img_url" is not valid string type: ${meta.collection_page_img_url}`);
    }
    // Check for the presence of the 'attributes' property, if it exists
    if (meta.attributes !== undefined && !Array.isArray(meta.attributes)) {
        throw new Error(`Property "meta.attributes" is not a valid array`);
    }
    // Validate each attribute in the 'attributes' array
    if (meta.attributes) {
        for (const attribute of meta.attributes) {
            try {
                validateInscriptionMetaAttribute(attribute);
            }
            catch (e) {
                throw e;
            }
        }
    }
    return true;
}
// Function to validate the InscriptionMetaAttribute type
function validateInscriptionMetaAttribute(attribute) {
    if (typeof attribute !== 'object' || attribute === null) {
        throw new Error(`Property "attribute" is not a valid object: ${attribute}`);
    }
    // Check for the presence of the 'trait_type' property
    if (typeof attribute.trait_type !== 'string') {
        throw new Error(`Property "attribute.trait_type" is not valid string type: ${attribute.trait_type}`);
    }
    // Check for the presence of the 'value' property
    if (typeof attribute.value !== 'string') {
        throw new Error(`Property "attribute.value" is not valid string type: ${attribute.value}`);
    }
    return true;
}
const findDuplicates = (items) => {
    const idsSeen = new Map();
    const duplicateIds = new Set();
    for (const item of items) {
        const currentCount = idsSeen.get(item.id) || 0;
        idsSeen.set(item.id, currentCount + 1);
        if (currentCount + 1 > 1) {
            duplicateIds.add(item.id);
        }
    }
    return Array.from(duplicateIds); // Convert set of duplicates to array
};
function findSubKInscriptions(inscriptions) {
    for (const inscription of inscriptions) {
        if (ord_10k_1.ord10k.includes(inscription.id)) {
            return inscription.id;
        }
    }
    return null;
}
exports.findSubKInscriptions = findSubKInscriptions;
