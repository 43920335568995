import { useMutation, UseMutationOptions } from 'react-query';
import { UserPresaleUpdatePayload } from '@ma/shared/admin';
import { AxiosError } from 'axios';
import { useAuth } from '../hooks';
import { getHttpClient, handleHttpClientError } from '../queries/http-client';

export const useToggleUserPresaleMutation = (
  opts: UseMutationOptions<
    void,
    AxiosError,
    { _id: string; body: UserPresaleUpdatePayload }
  > = {},
) => {
  const { authorization } = useAuth();
  const headers: Record<string, string> = {};
  if (authorization) {
    headers.authorization = authorization;
  }

  const updateUserPresale = async (variables: {
    _id: string;
    body: UserPresaleUpdatePayload;
  }) => {
    await getHttpClient({ headers }).patch(
      `/api/admin/users/${variables._id}/presale`,
      variables.body,
    );
  };

  return useMutation(updateUserPresale, {
    onError: handleHttpClientError,
    ...opts,
  });
};
