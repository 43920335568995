import { IReviewRequest } from '@ma/shared/admin';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { getHttpClient, handleHttpClientError } from 'src/queries/http-client';
import { useAuth } from 'src/hooks';

export const useReviewRequestResolutionMutation = (
  opts: UseMutationOptions<
    { data: IReviewRequest },
    AxiosError,
    {
      _id: string;
      // collectionId is used for analytics and represend
      // symbol in Solana collections
      // chain and contracts address for EVM collections
      collectionId: string | null;
      body: { action: string; message?: string };
    }
  > = {},
) => {
  const { authorization } = useAuth();
  const headers: Record<string, string> = {};
  if (authorization) {
    headers.authorization = authorization;
  }

  const createBlacklist = async (variables: {
    _id: string;
    body: { action: string; message?: string };
  }) => {
    const res = await getHttpClient({ headers }).post<{
      data: IReviewRequest;
    }>(
      `/api/admin/review-requests/${variables._id}/resolution`,
      variables.body,
    );
    return res.data;
  };

  return useMutation(createBlacklist, {
    onError: handleHttpClientError,
    ...opts,
  });
};
