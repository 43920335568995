import { IAdminCollectionDraft, IMeCollection } from '@ma/shared/creators';
import { CollectionDraftGradePayload } from '@ma/shared/admin';
import {
  Button,
  Drawer,
  Textarea,
  Select,
  Tabs,
  Text,
  Container,
  Group,
  Checkbox,
  MultiSelect,
  Title,
} from '@mantine/core';
import { useForm, UseFormReturnType } from '@mantine/form';
import { CollectionBadges } from 'src/components/CollectionBadges';
import {
  CollectionDraftViewer,
  MintsViewer,
  OperationsViewer,
} from 'src/components/collection-drafts';
import { ErrorMessage } from 'src/components/ErrorMessage';
import { SOURCE_OPTIONS } from 'src/entities/collection-draft';
import _capitalize from 'lodash-es/capitalize';
import _trim from 'lodash-es/trim';
import { MintsCardViewer } from './MintsCardViewer';

type GradeActionsFormProps = {
  draftId: string;
  draftVersion: number;
  form: UseFormReturnType<any>;
  onSubmit: (variables: { body: CollectionDraftGradePayload }) => void;
  isLoading?: boolean;
};

const reviewResolutionToAction = (reviewValues: {
  resolution?: string;
  shouldUpdateCollection?: boolean;
}): 'approve' | 'list' | 'reject' | 'review' => {
  if (!reviewValues.resolution) {
    return 'review';
  }

  if (reviewValues.resolution === 'accepted') {
    if (reviewValues.shouldUpdateCollection) {
      return 'list';
    }
    return 'approve';
  }

  if (reviewValues.resolution.startsWith('rejected')) {
    return 'reject';
  }

  throw new Error(
    `Can\'t convert resolution \`${reviewValues.resolution}\`to action`,
  );
};

const GradeActionsForm = (props: GradeActionsFormProps) => {
  return (
    <form
      onSubmit={props.form.onSubmit(formValues => {
        let reviewMsg = formValues.resolution;
        if (_trim(formValues.note).length) {
          reviewMsg += ` - ${_trim(formValues.note)}`;
        }
        props.onSubmit({
          body: {
            id: props.draftId,
            reviewMsg,
            type: reviewResolutionToAction(formValues),
            collectionDraftPayload: {
              source: formValues.source,
            },
            version: props.draftVersion,
          },
        });
      })}
    >
      <MultiSelect
        required
        label="Source"
        description="Dima: not sure if this field should be part of the review process"
        clearable
        data={[...SOURCE_OPTIONS]}
        {...props.form.getInputProps('source')}
      />
      <Select
        required
        searchable
        label="Review Resolution"
        description="visible to the creator of the draft"
        data={[
          'accepted',
          'rejected - missing information for listing',
          'rejected - NSFW',
          'rejected - IP infringement',
          'rejected - team or community discretion',
        ]}
        {...props.form.getInputProps('resolution')}
      />
      <Textarea
        label="Review Note"
        description="visible to the creatort of the draft"
        {...props.form.getInputProps('note')}
      />
      <ErrorMessage errors={props.form.errors} />
      <Group align="center">
        <Button
          type="submit"
          loading={props.isLoading}
          disabled={!props.form.isDirty()}
        >
          {_capitalize(reviewResolutionToAction(props.form.values))} Draft
        </Button>
        <Checkbox
          label="list / apply changes to listed collection"
          {...props.form.getInputProps('shouldUpdateCollection')}
          disabled={
            !['approve', 'list'].includes(
              reviewResolutionToAction(props.form.values),
            )
          }
        />
      </Group>
    </form>
  );
};

type CollectionDraftGradeProps = {
  // draft to grade
  draft: IAdminCollectionDraft;
  // related collection if the draft was listed
  collection?: IMeCollection | null;
  // last approved draft snapshot if the draft was approved at least once
  approved?: IAdminCollectionDraft | null;
  // internal review commentary
  internalReview?: { note?: string };
  onSubmit: (variables: { body: CollectionDraftGradePayload }) => void;
  onInternalReviewSubmit: (variables: {
    body: { id: string; note: string };
  }) => void;
  onClose: () => void;
  loading: boolean;
};

/**
 * Make listing actions on a collection draft
 * approve, reject, list, delist, leave internal comments
 */
export const CollectionDraftGrade = (props: CollectionDraftGradeProps) => {
  const reviewForm = useForm<any>({
    initialValues: {
      source: props.draft.source,
    },
    validate: {
      source: (value: any) => {
        if (!Array.isArray(value) || value.length < 1) {
          return 'source must be not empty list of source values';
        }
        return null;
      },
      review: (value: any) =>
        value && value.length && value.length === 0
          ? 'Must not be empty'
          : null,
      note: (value: any) =>
        value && value.length && value.length === 0
          ? 'Must not be empty'
          : null,
    },
  });

  const internalReviewForm = useForm<{ note: string }>({
    initialValues: {
      note: '',
      ...props.internalReview,
    },
  });

  return (
    <Drawer
      opened
      onClose={() => props.onClose()}
      title={
        <Group>
          {`Grade \`${props.draft.symbol}\` Collection`}
          <CollectionBadges draft={props.draft} collection={props.collection} />
        </Group>
      }
      padding="lg"
      size="1270px"
      styles={{ drawer: { overflowY: 'scroll' } }}
    >
      <Text mb="md">
        creator&apos;s comment:{' '}
        <Text span color="dimmed" mb="md">
          {props.draft.comment || 'no comment'}
        </Text>
      </Text>
      <form
        onSubmit={internalReviewForm.onSubmit(values =>
          props.onInternalReviewSubmit({
            body: {
              id: props.draft._id,
              note: values.note,
            },
          }),
        )}
      >
        <Group>
          <Textarea
            label="internal review note"
            placeholder="no note"
            mb="xl"
            cols={80}
            {...internalReviewForm.getInputProps('note')}
          />
          <Button
            type="submit"
            loading={props.loading}
            disabled={!internalReviewForm.isDirty()}
          >
            Update
          </Button>
        </Group>
      </form>
      <Tabs defaultValue="REVIEW" keepMounted={false}>
        <Tabs.List>
          <Tabs.Tab value="REVIEW">Review Draft Changes</Tabs.Tab>
          <Tabs.Tab value="MINTS">Mints</Tabs.Tab>
          <Tabs.Tab value="TRUSTSAFETY">Trust & Safety</Tabs.Tab>
          <Tabs.Tab value="OPERATIONS">All Operations</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="REVIEW">
          <Container mt="xl">
            <CollectionDraftViewer
              draft={props.draft}
              approved={props.approved}
            />
            <Text mb="sm" weight={700}>
              Last 5 operations:
            </Text>
            <OperationsViewer ops={props.draft.operations} limit={5} compact />
            <GradeActionsForm
              draftId={props.draft._id}
              draftVersion={props.draft.version ?? 0}
              form={reviewForm}
              onSubmit={props.onSubmit}
            />
          </Container>
        </Tabs.Panel>
        <Tabs.Panel value="MINTS">
          <Container mt="xl">
            <MintsViewer draft={props.draft} />
          </Container>
        </Tabs.Panel>
        <Tabs.Panel value="TRUSTSAFETY">
          <Container mt="xl">
            <Title size="h1">Sample Mints</Title>
            {props.draft.sampleMints && props.draft.sampleMints.length > 0 && (
              <MintsCardViewer mints={props.draft.sampleMints} />
            )}
            <Title size="h1">Similar Mints</Title>
            {props.draft.similarMints?.results &&
              props.draft.similarMints?.results.length > 0 && (
                <MintsCardViewer mints={props.draft.similarMints.results} />
              )}
          </Container>
        </Tabs.Panel>
        <Tabs.Panel value="OPERATIONS">
          <Container mt="xl">
            <OperationsViewer ops={props.draft.operations} />
          </Container>
        </Tabs.Panel>
      </Tabs>
    </Drawer>
  );
};
