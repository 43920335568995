import { ICollectionDraftResponse } from '@ma/shared/creators';
import {
  CollectionDraftGradePayload,
  CollectionDraftUpdatePayload,
} from '@ma/shared/admin';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';
import { useAuth } from 'src/hooks';
import { getHttpClient } from 'src/queries/http-client';

export const useUpdateCollectionDraftMutation = (
  opts: UseMutationOptions<
    { data: ICollectionDraftResponse },
    AxiosError,
    { draftId: string; body: CollectionDraftUpdatePayload }
  > = {},
) => {
  const { authorization } = useAuth();
  const headers: Record<string, string> = {};
  if (authorization) {
    headers.authorization = authorization;
  }

  const updateCollectionDraft = async (variables: {
    draftId: string;
    body: CollectionDraftUpdatePayload;
  }) => {
    const res = await getHttpClient({ headers }).patch<{
      data: ICollectionDraftResponse;
    }>(`/api/admin/collection-drafts/${variables.draftId}`, variables.body);
    return res.data;
  };

  return useMutation(updateCollectionDraft, opts);
};

export const useGradeCollectionDraftMutation = (
  opts: UseMutationOptions<
    ICollectionDraftResponse,
    AxiosError,
    { body: CollectionDraftGradePayload }
  > = {},
) => {
  const { authorization } = useAuth();
  const headers: Record<string, string> = {};
  if (authorization) {
    headers.authorization = authorization;
  }

  const updateCollectionDraft = async (variables: {
    body: CollectionDraftGradePayload;
  }) => {
    const res = await getHttpClient({ headers }).post<ICollectionDraftResponse>(
      `/api/admin/grade-collection-draft`,
      variables.body,
    );
    return res.data;
  };

  return useMutation(updateCollectionDraft, opts);
};

export const useUpdateInternalReviewMutation = (
  opts: UseMutationOptions<
    { note: string },
    AxiosError,
    { body: { id: string; note: string } }
  > = {},
) => {
  const { authorization } = useAuth();
  const headers: Record<string, string> = {};
  if (authorization) {
    headers.authorization = authorization;
  }

  const updateDraftInternalReview = async (variables: {
    body: { id: string; note: string };
  }) => {
    const res = await getHttpClient({ headers }).post<{
      note: string;
    }>(`/api/admin/collection-reviews/new`, variables.body);
    return res.data;
  };

  return useMutation(updateDraftInternalReview, opts);
};
