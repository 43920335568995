"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlacklistAccountType = void 0;
var BlacklistAccountType;
(function (BlacklistAccountType) {
    BlacklistAccountType["TWITTER_ID"] = "twitter_id";
    BlacklistAccountType["EMAIL"] = "email";
    BlacklistAccountType["METAPLEX_CERTIFIED_COLLECTION"] = "metaplex_certified_collection";
    BlacklistAccountType["UPDATE_AUTHORITY"] = "update_authority";
    BlacklistAccountType["CANDY_MACHINE_ID"] = "candy_machine_id";
})(BlacklistAccountType = exports.BlacklistAccountType || (exports.BlacklistAccountType = {}));
