import { AxiosRequestConfig } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import {
  getMagicEdenHttpClient,
  getQueryPathFromQueryKey,
  handleHttpClientError,
  QueryKey,
} from './http-client';

const magicEdenApi = getMagicEdenHttpClient();

type MagicEdenQueryMeta = { authorization?: string } & Record<string, unknown>;

/**
 * A generic query function for react-query useQuery
 * allows to pass only query key to make a HTTP GET query to Creator Hub API
 *
 * @example
 *    // makes GET /rpc/getNFTByMintAddress HTTP query
 *    useMagicEdenQuery(['rpc', 'getNFTByMintAddress'])
 *
 * @param params
 * @param params.queryKey - array of react-query useQuery keys,
 *    to add query string pass an object with query string key values as last item
 * @param params.meta - useQuery meta object
 * @param params.meta.authorization - authorization herader, e.g. "Bearer some-auth-token", optional
 */
const magicEdenApiQueryFn = async <TData>({
  queryKey,
  meta = {},
}: {
  queryKey: QueryKey;
  meta: MagicEdenQueryMeta | undefined;
}) => {
  let headers: AxiosRequestConfig['headers'];
  if (meta.authorization) {
    headers = headers ?? {};
    headers.authorization = meta.authorization;
  }

  const [endpointPath, queryStringParams] = getQueryPathFromQueryKey(queryKey);

  const res = await magicEdenApi.get<TData>(endpointPath + queryStringParams, {
    headers,
  });
  return res.data;
};

/**
 * Specifig query functions
 */

export const getListNftsByMintAddresses = async (mintAddresses: string[]) => {
  if (mintAddresses.length === 0) {
    return Promise.resolve([]);
  }
  const reqPromises = mintAddresses.map(async mint => {
    const res = await magicEdenApi.get(`/rpc/getNFTByMintAddress/${mint}`);
    return res.data;
  });

  const responses = await Promise.allSettled(reqPromises);
  return responses
    .filter(
      (
        res,
      ): res is PromiseFulfilledResult<{
        results: { img: string; title: string; id: string } | undefined;
      }> => res.status === 'fulfilled',
    )
    .map(res => res.value.results);
};

export const useMagicEdenQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
>(
  queryKey: QueryKey,
  opts: UseQueryOptions<TQueryFnData, TError, TData, QueryKey> = {},
) => {
  return useQuery({
    queryKey,
    queryFn: magicEdenApiQueryFn,
    retry: 0,
    refetchOnWindowFocus: false,
    onError: handleHttpClientError,
    ...opts,
  });
};
