import { IBlacklist } from '@ma/shared/admin';
import { Checkbox } from '@mantine/core';
import { useCallback, useMemo, useState } from 'react';
import { MeTable, MeTableCell } from 'src/components/MeTable';

type BlacklistTableProps = {
  data: IBlacklist[];
  selectedRowsIds: ReadonlySet<string>;
  setSelectedRowsIds: (set: ReadonlySet<string>) => void;
};

export const useSelectedRows = () => {
  const [selectedRowsIds, setSelectedRowsIds] = useState<ReadonlySet<string>>(
    new Set(),
  );

  const reset = useCallback(
    () => setSelectedRowsIds(new Set()),
    [setSelectedRowsIds],
  );

  return {
    selectedRowsIds,
    setSelectedRowsIds,
    reset,
  };
};

/**
 * Table to display blacklist documents
 */
export const BlacklistTable = (props: BlacklistTableProps) => {
  const { data, selectedRowsIds, setSelectedRowsIds } = props;

  const columns = useMemo(
    () => [
      {
        accessor: '_selectRow',
        Header() {
          return (
            <Checkbox
              checked={
                selectedRowsIds.size === data.length && data.length !== 0
              }
              indeterminate={
                selectedRowsIds.size !== 0 &&
                selectedRowsIds.size !== data.length
              }
              label={selectedRowsIds.size === 0 ? 'select all' : 'unselect all'}
              onChange={event => {
                if (event.currentTarget.checked) {
                  setSelectedRowsIds(new Set(data.map(item => item._id)));
                } else {
                  setSelectedRowsIds(new Set());
                }
              }}
            />
          );
        },
        Cell(cellProps: MeTableCell<IBlacklist>) {
          return (
            <Checkbox
              checked={selectedRowsIds.has(cellProps.row?._id)}
              onChange={event => {
                if (event.currentTarget.checked) {
                  setSelectedRowsIds(
                    new Set([...selectedRowsIds, cellProps.row?._id]),
                  );
                } else {
                  setSelectedRowsIds(
                    new Set(
                      [...selectedRowsIds].filter(
                        item => item !== cellProps.row?._id,
                      ),
                    ),
                  );
                }
              }}
            />
          );
        },
        columnProps: { width: '200px' },
      },
      {
        accessor: 'accountType',
        Header: 'Type',
      },
      {
        accessor: 'value',
        Header: 'Value',
      },
    ],
    [data, selectedRowsIds, setSelectedRowsIds],
  );
  return <MeTable highlightOnHover columns={columns} data={data} />;
};
