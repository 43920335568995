import { ICollectionDraftResponse } from '@ma/shared/creators';
import { Button, Group, Image, List, Skeleton, Text } from '@mantine/core';
import { useState } from 'react';
import { MeCopyButton } from 'src/components/MeCopyButton';
import { MeTextAddress } from 'src/components/MeTextAddress';
import { getListNftsByMintAddresses, useMagicEdenQuery } from 'src/queries';

type MintsViewerProps = {
  draft: ICollectionDraftResponse;
};

export interface MintPreviewProps {
  mints: string[];
}
export function MintPreview(props: MintPreviewProps) {
  const limit = 5;
  const [offset, setOffset] = useState(0);

  const mintsToView = props.mints.slice(offset, offset + limit);

  const nfts = useMagicEdenQuery(
    ['rpc', getListNftsByMintAddresses.name, mintsToView.join('_')],
    {
      queryFn: () => getListNftsByMintAddresses(mintsToView),
    },
  );

  if (!nfts.data?.length) {
    return null;
  }

  return (
    <Group>
      {nfts.data &&
        nfts.data.map((nft, idx) => (
          <Image
            key={nft?.id ?? `nft_${idx}`}
            width={100}
            height={100}
            src={nft?.img}
            alt={nft?.title}
          />
        ))}
      {nfts.isFetching &&
        Array(limit)
          .fill(null)
          .map((_, idx) => {
            return <Skeleton key={idx} width={100} height={100} />;
          })}
      {props.mints.length > offset + limit && (
        <Button
          variant="subtle"
          onClick={() => setOffset(prev => prev + limit)}
        >
          Next {limit}
        </Button>
      )}
    </Group>
  );
}
export const MintsViewer = (props: MintsViewerProps) => {
  if (!props.draft.mint || !props.draft.mint.length) {
    return <Text>Collection Draft has empty hash list</Text>;
  }

  return (
    <div>
      <MintPreview mints={props.draft.mint} />
      <List>
        {props.draft.mint.map((mintAddress, idx) => {
          const key = mintAddress + '__' + idx;
          return (
            <List.Item key={key} icon={<MeCopyButton value={mintAddress} />}>
              <MeTextAddress address={mintAddress} />
            </List.Item>
          );
        })}
      </List>
    </div>
  );
};
