import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useCallback, useMemo } from 'react';

export const useAuth = (): IAuth => {
  const { oktaAuth, authState } = useOktaAuth();
  const oktaAccessToken = oktaAuth.getAccessToken();

  const redirectBackUri = toRelativeUrl(
    window.location.href,
    window.location.origin,
  );

  const signin = useCallback(() => {
    if (authState === null) {
      // wait untill we get authState object
      return;
    }
    oktaAuth.setOriginalUri(redirectBackUri);
    oktaAuth.signInWithRedirect();
  }, [redirectBackUri, oktaAuth, authState]);

  const signout = useCallback(() => {
    oktaAuth.signOut({
      postLogoutRedirectUri: `${window.location.origin}/signout`,
    });
  }, [oktaAuth]);

  return useMemo(() => {
    if (authState?.isAuthenticated && oktaAccessToken) {
      return {
        isAuthenticated: true,
        token: oktaAccessToken,
        authorization: `Bearer ${oktaAccessToken}`,
        signin,
        signout,
      };
    }

    return {
      isAuthenticated: false,
      token: null,
      authorization: null,
      signin,
      signout,
    };
  }, [authState?.isAuthenticated, oktaAccessToken, signin, signout]);
};

export type IAuth =
  // authenticated state
  | {
      isAuthenticated: true;
      token: string;
      // Authorization header string
      authorization: string;
      // Initiate sign in
      signin: () => void;
      // Initiate sign out
      signout: () => void;
      // not yet implemented
      // profile: UseQueryResult<any, unknown>;
      // renewToken: () => Promise<void>;
    }
  // not authenticated state
  | {
      isAuthenticated: false;
      token: null;
      authorization: null;
      signin: () => void;
      signout: () => void;
    };
