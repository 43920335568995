import { DeepPartial } from '@ma/shared/util';
import { FormErrors } from '@mantine/form';
import { UseFormInput } from '@mantine/form/lib/types';
import { Struct, StructError, validate, Infer } from 'superstruct';

/**
 * Validation for mantine/form with superstruct
 */

interface ValidationOptions {
  coerce?: boolean;
}

const DEFAULT_OPTS: ValidationOptions = {
  coerce: true,
};

/**
 * handles cases when there are no validation errors and there are
 * validation errors and returns an object where keys are form fields
 * and values are validation error messages that mantine/forms
 * is expecting
 */
const structErrorsToFormErrors = (err: StructError | null | undefined) => {
  const formErrs: Record<string, string> = {};
  if (!err) {
    return formErrs;
  }

  for (const fieldError of err.failures()) {
    const fieldName = fieldError.path.join(' ');
    formErrs[fieldError.path.join('.')] = fieldName + ': ' + fieldError.message;
  }
  return formErrs;
};

/**
 * NOTE: mantine/form only works with synchronous validate functions
 *
 * @returns function for valiate property of mantine/form useForm
 *
 * @example
 *    useForm({
 *      validate: structFormValidation(structSchema)
 *    })
 */
export function structFormValidation<T, S>(
  schema: Struct<T, S>,
  opts: Partial<ValidationOptions> = {},
) {
  return (values: any): FormErrors => {
    const [err, _values] = validate(values, schema, {
      ...DEFAULT_OPTS,
      ...opts,
    });
    return structErrorsToFormErrors(err);
  };
}

export const structForm = <T extends Infer<S>, S extends Struct<any, any>>(
  props: DeepPartial<T>,
  struct: S,
  opts: Partial<ValidationOptions> = {},
): UseFormInput<T> => {
  const initialValues = {} as T;
  // remove fields that do not in the schema from form values
  // TODO: remove nested fields that are no in the schema
  for (const schemaProp in struct.schema) {
    initialValues[schemaProp] = props[schemaProp];
  }
  return {
    initialValues,
    validate: structFormValidation<T, S>(struct, opts),
  };
};
