"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.findProgramAddress = exports.programIds = void 0;
const web3_js_1 = require("@solana/web3.js");
const Constants_1 = require("../metaplex/Constants");
let STORE;
const programIds = () => {
    return {
        token: Constants_1.TOKEN_PROGRAM_ID,
        associatedToken: Constants_1.SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
        bpf_upgrade_loader: Constants_1.BPF_UPGRADE_LOADER_ID,
        system: Constants_1.SYSTEM,
        metadata: Constants_1.METADATA_PROGRAM_ID,
        memo: Constants_1.MEMO_ID,
        vault: Constants_1.VAULT_ID,
        auction: Constants_1.AUCTION_ID,
        metaplex: Constants_1.METAPLEX_ID,
        store: STORE,
    };
};
exports.programIds = programIds;
const findProgramAddress = (seeds, programId) => __awaiter(void 0, void 0, void 0, function* () {
    const result = yield web3_js_1.PublicKey.findProgramAddress(seeds, programId);
    return [result[0].toBase58(), result[1]];
});
exports.findProgramAddress = findProgramAddress;
