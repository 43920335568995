"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatDate = exports.formatTime = exports.formatDateTime = exports.convertUtcToLocalLiterally = exports.convertTimeToUtcLiterally = void 0;
const date_fns_1 = require("date-fns");
/**
 * @param date
 *
 * Convert date to same date but in UTC
 * e.g. 02/08/2022 12:15pm GMT-0800 will becomes 02/08/2022 12:15pm UTC, no timezone conversion is involved
 */
function convertTimeToUtcLiterally(date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
}
exports.convertTimeToUtcLiterally = convertTimeToUtcLiterally;
/**
 *
 * @param date
 * The opposite of convertTimeToUtcLiterally
 * e.g. 02/08/2022 12:15pm UTC becomes 02/08/2022 12:15pm GMT-0800
 */
function convertUtcToLocalLiterally(date) {
    return (0, date_fns_1.addMinutes)(date, new Date().getTimezoneOffset());
}
exports.convertUtcToLocalLiterally = convertUtcToLocalLiterally;
function formatDateTime(date) {
    return date.toLocaleString();
}
exports.formatDateTime = formatDateTime;
function formatTime(date) {
    return date.toLocaleTimeString(undefined, { timeStyle: 'short' });
}
exports.formatTime = formatTime;
function formatDate(date) {
    return date.toLocaleDateString();
}
exports.formatDate = formatDate;
