"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSupportedReviewRequestResource = exports.isRejected = exports.isInReviewPipeline = exports.REVIEW_REQUEST_DEFAULT_REJECT_MESSAGE = exports.REVIEW_REQUEST_STATUS = exports.REVIEW_REQUEST_RESOURCE = void 0;
exports.REVIEW_REQUEST_RESOURCE = Object.freeze([
    'AnnouncementDraft',
]);
exports.REVIEW_REQUEST_STATUS = Object.freeze([
    'pending',
    'approved',
    'rejected',
    'archived',
]);
exports.REVIEW_REQUEST_DEFAULT_REJECT_MESSAGE = 'We’re sorrry, we’re unable to post your announcement. Please try editing and re-submit or reach out to support@magiceden.io for details.';
/**
 * Check whether a resource is in the review process
 */
function isInReviewPipeline(resource) {
    var _a;
    return ((_a = resource.reviewRequest) === null || _a === void 0 ? void 0 : _a.status) === 'pending';
}
exports.isInReviewPipeline = isInReviewPipeline;
/**
 * Check whether a resource was rejected in the last review
 */
function isRejected(resource) {
    var _a;
    return ((_a = resource.reviewRequest) === null || _a === void 0 ? void 0 : _a.status) === 'rejected';
}
exports.isRejected = isRejected;
const isSupportedReviewRequestResource = (val) => {
    return exports.REVIEW_REQUEST_RESOURCE.includes(val);
};
exports.isSupportedReviewRequestResource = isSupportedReviewRequestResource;
