import { getRudderParams, getNetwork, getEnv } from '@ma/shared/services';
import * as rudderStack from 'rudder-sdk-js';

export const INSTRUMENTATION_EVENT_CREATOR_HUB =
  'instrumentation_event_creator_hub';

/**
 *  Types
 */

export enum AnalyticEvent {
  CREATOR_ANNOUNCEMENT_APPROVED = 'creator_announcement_approved',
  CREATOR_ANNOUNCEMENT_REJECTED = 'creator_announcement_rejected',
  CREATOR_ANNOUNCEMENT_ARCHIVED = 'creator_announcement_archived',
}

export enum PageCategory {
  COLLECTION_DRAFT_SUBMISSION = 'collection_draft_submission',
}

type IdentityParams = {
  userId: string;
};

/**
 * Initialize
 */

rudderStack.ready(() => console.log('analytics init.'));
const [rudderKey, websiteUrl, extras] = getRudderParams();
rudderStack.load(rudderKey, websiteUrl, extras);

const { hostname: siteName } = window.location;

class Analytics {
  /**
   * Identify a visiting user and associate them to their actions
   * and store users traits like email, name, etc
   *
   * when to call https://www.rudderstack.com/docs/rudderstack-api/api-specification/rudderstack-spec/identify/#when-should-i-call-identify
   */
  identity({ userId }: IdentityParams) {
    rudderStack.identify(userId, {
      siteName,
      lang: 'en',
    });
  }

  /**
   * writes all events to a singular table in DBC, `instrumentation_event_creator_hub` table
   */
  async trackAggregate({
    eventName,
    ...properties
  }: { eventName: AnalyticEvent } & Record<string, string | number>) {
    if (!eventName) {
      if (this.isLocalhost()) {
        console.log(
          `Cannot track event ${INSTRUMENTATION_EVENT_CREATOR_HUB}, properties.eventName is required.`,
        );
      }
      return;
    }
    const internalEventName = `rudderstack.${eventName}`;

    const params = {
      properties: JSON.stringify({
        eventName,
        ...properties,
      }),
      siteName,
      lang: 'en',
      page: location.pathname,
      href: location.href,
      env: getNetwork(),
    };

    rudderStack.track(INSTRUMENTATION_EVENT_CREATOR_HUB, params);

    if (this.isLocalhost()) {
      console.info(
        `[event: ${INSTRUMENTATION_EVENT_CREATOR_HUB} - ${internalEventName}]`,
        {
          params,
        },
      );
    }
  }

  /**
   * Reset current user identity
   */
  reset() {
    rudderStack.reset();
  }

  isLocalhost() {
    return getEnv() === 'local';
  }
}

export const analytics = new Analytics();
