import { useCallback, useState } from 'react';

type UsePaginationOptions = {
  initialPage?: number;
  pageSize: number;
};

export const usePagination = (opts: UsePaginationOptions) => {
  const initialPage = Math.max(opts.initialPage || 0, 1);
  const pageSize = opts.pageSize;

  const [currentPage, setPage] = useState(initialPage);

  return {
    currentPage,
    setPage,
    offset: (currentPage - 1) * pageSize,
    getTotalPages: useCallback(
      (totalItems: number) => Math.ceil(totalItems / pageSize),
      [pageSize],
    ),
    hasPages: useCallback(
      (totalItems: number) => totalItems > pageSize,
      [pageSize],
    ),
  };
};
