import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { AdminLayout } from '../components/layout/AdminLayout';
import { useCreatorHubQuery } from '../queries';
import { IUserLight } from '@ma/shared/launchpad';
import { UsersTableAction, UsersTable } from '../components/users';
import { useToggleUserPresaleMutation } from '../mutations/users';
import notifications from '../utils/notifications';
import { IPage } from '@ma/backend/utils/paginate';
import { usePagination } from '../hooks';
import { Pagination, TextInput } from '@mantine/core';
import { TbSearch, TbBarrierBlock, TbBarrierBlockOff } from 'react-icons/tb';
import { usePrevious } from '@mantine/hooks';

const PAGE_SIZE = 25;

export const UsersPage: FC = () => {
  // TODO: Think of moving current user to the top level provider
  const currentUserId = useCreatorHubQuery<{ _id: string }>([
    'api',
    'admin',
    'me',
  ]).data?._id;

  const [searchQueryString, setSearchQueryString] = useState<string>();

  const pagination = usePagination({ pageSize: PAGE_SIZE });

  // Reset page when query string is changed
  const prevSearchQueryString = usePrevious(searchQueryString);
  useEffect(() => {
    if (
      prevSearchQueryString !== undefined &&
      prevSearchQueryString !== searchQueryString
    ) {
      pagination.setPage(1);
    }
  }, [pagination, prevSearchQueryString, searchQueryString]);

  const usersListQuery = useCreatorHubQuery<IPage<IUserLight[]>>([
    'api',
    'admin',
    'users',
    {
      limit: PAGE_SIZE,
      offset: pagination.offset,
      ...(searchQueryString ? { q: searchQueryString } : {}),
    },
  ]);

  const toggleUserPresaleMutation = useToggleUserPresaleMutation({
    onSuccess: () => {
      usersListQuery.refetch();
      notifications.success('user presale status has been toggled');
    },
  });

  const toggleUserPresale = useCallback(
    (_id: string, enable: boolean) => {
      toggleUserPresaleMutation.mutate({
        _id,
        body: {
          enable,
        },
      });
    },
    [toggleUserPresaleMutation],
  );

  const usersData = useMemo(
    () =>
      (usersListQuery.data?.data ?? []).map(user => {
        const actions: UsersTableAction[] = [];

        const managePresale = Boolean(
          user.presale && currentUserId && user._id !== currentUserId,
        );

        if (managePresale && user.presale?.enabled) {
          actions.push({
            title: 'Block presale',
            icon: <TbBarrierBlock />,
            color: 'red',
            onClick: () => toggleUserPresale(user._id, false),
          });
        } else if (managePresale && !user.presale?.enabled) {
          actions.push({
            title: 'Unblock presale',
            icon: <TbBarrierBlockOff />,
            color: 'green',
            onClick: () => toggleUserPresale(user._id, true),
          });
        }

        return {
          ...user,
          actions,
        };
      }),
    [currentUserId, toggleUserPresale, usersListQuery.data?.data],
  );

  return (
    <AdminLayout
      breadcrumbs={[{ title: 'Users', path: '/users' }]}
      loading={usersListQuery.isFetching}
    >
      <TextInput
        autoFocus
        icon={<TbSearch />}
        description="Search by exact ID or email"
        onBlur={event => {
          setSearchQueryString(event.currentTarget.value.trim());
        }}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            // set search value when enter key is pressed
            setSearchQueryString(event.currentTarget.value.trim());
          }
        }}
      />
      <UsersTable data={usersData} />
      {pagination.hasPages(usersListQuery.data?.total ?? 0) && (
        <Pagination
          withEdges
          boundaries={3}
          siblings={3}
          page={pagination.currentPage}
          onChange={pagination.setPage}
          total={pagination.getTotalPages(usersListQuery.data?.total ?? 0)}
        />
      )}
    </AdminLayout>
  );
};
