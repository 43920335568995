import {
  Blockchain,
  IAdminCollectionDraft,
  IMeCollection,
  SubmissionStatus,
} from '@ma/shared/creators';
import {
  Text,
  Chip,
  Button,
  Group,
  Pagination,
  Select,
  TextInput,
} from '@mantine/core';
import _get from 'lodash/get';
import _pickBy from 'lodash/pickBy';
import { useEffect, useState } from 'react';
import { AdminLayout } from 'src/components/layout/AdminLayout';
import { usePagination } from 'src/hooks';
import {
  CollectionCreateEditor,
  CollectionDraftsTable,
  CollectionDraftsEditor,
  CollectionDraftGrade,
} from 'src/components/collection-drafts';
import { TbPlus, TbSearch } from 'react-icons/tb';
import {
  useCreateCollectionMutation,
  useGradeCollectionDraftMutation,
  useUpdateCollectionDraftMutation,
  useUpdateCollectionMutation,
  useUpdateInternalReviewMutation,
} from 'src/mutations';
import notifications from 'src/utils/notifications';
import { useCreatorHubQuery } from 'src/queries';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';

// TODO extract duplicate of IPage interface

interface IPage<T> {
  data: T;
  limit: number;
  offset: number;
  total: number;
}

interface IAdminApiResponse<T> {
  data: T;
  msg?: string;
}

const isQueryParamValue = (val: any): boolean => {
  return val !== null && val !== undefined && val !== '';
};

// end of FIXME

const TFilter = Object.freeze(['only', 'not'] as const);
type TFilter = typeof TFilter[number];

const PAGE_SIZE = 25;

export const ListCollectionDraftsPage = () => {
  const pagination = usePagination({ pageSize: PAGE_SIZE });

  /* Filters */
  // search drafts by name & symbol filter
  const [searchQueryString, setSearchQueryString] = useState<string>('');
  // filter drafts by submissionStatus
  const [filterSubmissionStatus, setFilterSubmissionStatus] =
    useState<SubmissionStatus>(SubmissionStatus.REVIEW);
  // filter drafts by derivative "flag"
  const [filterDerivative, setFilterDerivative] = useState<TFilter | null>(
    null,
  );
  // filter drafts by isAutolisted flag
  const [filterAutolisted, setFilterAutolisted] = useState<TFilter | null>(
    null,
  );
  // filter drafts by listed flag
  const [filterListed, setFilterListed] = useState<boolean>(false);
  // Filter drafts by chain
  const [filterChain, setFilterChain] = useState<Blockchain | null>(null);

  /* Modals & Drawers state */
  // indicate wether the edit collection drawer is open
  const [editCollectionDraftiId, setEditCollectionDraftId] = useState<
    null | string
  >(null);
  // indicate wether the grade actions drawer is open
  const [gradeCollectionDraftiId, setGradeCollectionDraftId] = useState<
    null | string
  >(null);
  // indicate wether is the onboard new collection drawer is open
  const [isCreateCollectionOpen, setCreateCollectionOpen] =
    useState<boolean>(false);

  const [lastRefreshed, setLastRefreshed] = useState<string>('...loading');
  // queries
  const collectionDraftsListQuery = useCreatorHubQuery<
    IPage<IAdminCollectionDraft[]>
  >(
    [
      'api',
      'admin',
      'collection-drafts',
      _pickBy(
        {
          limit: PAGE_SIZE,
          offset: pagination.offset,
          submissionStatus: filterSubmissionStatus,
          isDerivative: filterDerivative,
          isAutolisted: filterAutolisted,
          isListed: filterListed,
          q: searchQueryString,
          chain: filterChain,
        } as any,
        isQueryParamValue,
      ),
    ],
    {
      cacheTime: 0,
      refetchInterval: 10 * 1000, // every 10 seconds
    },
  );

  const collectionDraftsTotalReviewQuery = useCreatorHubQuery<
    IAdminCollectionDraft[]
  >(
    [
      'api',
      'admin',
      'collection-drafts',
      _pickBy({
        submissionStatus: SubmissionStatus.REVIEW,
      } as any),
    ],
    {
      cacheTime: 0,
      refetchInterval: 10 * 1000, // every 10 seconds
    },
  );

  useEffect(() => {
    const updateLastRefreshed = () => {
      setLastRefreshed(
        formatDistanceStrict(
          new Date(collectionDraftsListQuery.dataUpdatedAt),
          new Date(),
          { addSuffix: true },
        ),
      );
    };
    const intervalId = setInterval(updateLastRefreshed, 1000);
    return () => clearInterval(intervalId);
  });

  const editCollectionDraftQuery = useCreatorHubQuery<IAdminCollectionDraft>(
    [
      'api',
      'admin',
      'collection-drafts',
      editCollectionDraftiId ?? gradeCollectionDraftiId,
    ],
    {
      enabled:
        editCollectionDraftiId !== null || gradeCollectionDraftiId !== null,
      cacheTime: 0,
    },
  );
  // related listed collection (if existst) to the draft
  const editCollectionQuery = useCreatorHubQuery<
    IAdminApiResponse<IMeCollection>
  >(
    [
      'api',
      'admin',
      'collection-drafts',
      editCollectionDraftQuery.data?._id,
      'collection',
    ],
    {
      enabled: editCollectionDraftQuery.data?._id != null,
      cacheTime: 0,
    },
  );
  // last approved version of the draft (if exists)
  const lastApprovedCollectionQuery = useCreatorHubQuery<{
    data: IAdminCollectionDraft;
  }>(
    [
      'api',
      'admin',
      'collection-drafts',
      editCollectionDraftQuery.data?._id,
      'last-approved',
    ],
    {
      enabled: editCollectionDraftQuery.data?._id != null,
      cacheTime: 0,
    },
  );
  // last approved version of the draft (if exists)
  const internalReviewQuery = useCreatorHubQuery<{ note?: string }>(
    ['api', 'admin', 'collection-reviews', editCollectionDraftQuery.data?._id],
    {
      enabled: editCollectionDraftQuery.data?._id != null,
      cacheTime: 0,
    },
  );

  // mutations
  const createCollectionMutation = useCreateCollectionMutation({
    onSuccess: (_data, params) => {
      collectionDraftsListQuery.refetch();
      setCreateCollectionOpen(false);
      notifications.success(
        `${params.body.symbol} collection has been created`,
      );
    },
    onError: error => {
      notifications.error(
        'Error code ' +
          error?.response?.status +
          ': ' +
          error?.response?.data?.msg,
      );
    },
  });
  const updateCollectionMutation = useUpdateCollectionMutation({
    onSuccess: (_data, _params) => {
      setEditCollectionDraftId(null);
      notifications.success(`collection data has been updates`);
    },
    onError: error => {
      notifications.error(
        'Error code ' +
          error?.response?.status +
          ': ' +
          error?.response?.data?.msg,
      );
    },
  });
  const updateDraftMutation = useUpdateCollectionDraftMutation({
    onSuccess: (_data, params) => {
      collectionDraftsListQuery.refetch();
      setEditCollectionDraftId(null);
      notifications.success(
        `${params.body.symbol} collection has been updates`,
      );
    },
    onError: error => {
      notifications.error(
        'Error code ' +
          error?.response?.status +
          ': ' +
          error?.response?.data?.msg,
      );
    },
  });
  const gradeDraftMutation = useGradeCollectionDraftMutation({
    onSuccess: (_data, params) => {
      collectionDraftsListQuery.refetch();
      setGradeCollectionDraftId(null);
      notifications.success(`${params.body.type} collection has been updates`);
    },
    onError: error => {
      notifications.error(
        'Error code ' +
          error?.response?.status +
          ': ' +
          error?.response?.data?.msg,
      );
    },
  });
  const updateInternalReviewMutation = useUpdateInternalReviewMutation({
    onSuccess: (_data, _params) => {
      notifications.success('internal review has been updated');
    },
    onError: error => {
      notifications.error(
        'Error code ' +
          error?.response?.status +
          ': ' +
          error?.response?.data?.msg,
      );
    },
  });

  return (
    <AdminLayout
      breadcrumbs={[
        { title: 'Listing Applications', path: '/collection-draft' },
      ]}
      loading={
        editCollectionDraftQuery.isFetching || editCollectionQuery.isFetching
      }
      header={<Text color="dimmed">last refreshed {lastRefreshed}</Text>}
      totalReviews={collectionDraftsTotalReviewQuery.data?.length ?? 0}
    >
      {/* page actions and filters */}
      <Group>
        <TextInput
          autoFocus
          icon={<TbSearch />}
          description="Search by symbol and name"
          onBlur={event => {
            setSearchQueryString(event.currentTarget.value.trim());
          }}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              // set search value when enter key is presed
              setSearchQueryString(event.currentTarget.value.trim());
            }
          }}
        />
        <Select
          clearable
          description="Filter by Status"
          placeholder="do not filter"
          data={[...Object.values(SubmissionStatus), '']}
          value={filterSubmissionStatus}
          onChange={value =>
            setFilterSubmissionStatus(value as SubmissionStatus)
          }
        />
        <Select
          clearable
          description="Filter by Derivative Flag"
          placeholder="do not filter"
          data={Array.from(TFilter)}
          value={filterDerivative}
          onChange={value => setFilterDerivative(value as TFilter | null)}
        />
        <Select
          clearable
          description="Filter Autolisted"
          placeholder="do not filter"
          data={Array.from(TFilter)}
          value={filterAutolisted}
          onChange={value => setFilterAutolisted(value as TFilter | null)}
        />
        <Select
          clearable
          description="Filter by Blockchain"
          placeholder="All chains"
          data={[...Object.values(Blockchain), '']}
          value={filterChain}
          onChange={value => setFilterChain(value as Blockchain | null)}
        />
        <Chip
          checked={filterListed}
          onChange={() => setFilterListed(val => !val)}
        >
          only listed
        </Chip>
        <Button
          leftIcon={<TbPlus />}
          onClick={() => setCreateCollectionOpen(true)}
        >
          Onboard New Collection
        </Button>
      </Group>

      {/* collection drafts table */}
      <CollectionDraftsTable
        collectionDrafts={collectionDraftsListQuery.data?.data || []}
        onEditClick={(id: string) => setEditCollectionDraftId(id)}
        onGradeClick={(id: string) => setGradeCollectionDraftId(id)}
      />

      {/* table pagination */}
      {pagination.hasPages(collectionDraftsListQuery.data?.total || 0) && (
        <Pagination
          withEdges
          boundaries={3}
          siblings={3}
          page={pagination.currentPage}
          onChange={pagination.setPage}
          total={pagination.getTotalPages(
            collectionDraftsListQuery.data?.total || 0,
          )}
        />
      )}

      {/* collection create form */}
      {isCreateCollectionOpen && (
        <CollectionCreateEditor
          onSubmit={createCollectionMutation.mutate}
          onClose={() => setCreateCollectionOpen(false)}
          loading={createCollectionMutation.isLoading}
        />
      )}

      {/* collection drafts & related listed collection editor */}
      {editCollectionDraftiId &&
        editCollectionDraftQuery.data &&
        editCollectionQuery.isFetched && (
          <CollectionDraftsEditor
            collection={editCollectionQuery.data?.data}
            draft={editCollectionDraftQuery.data}
            internalReview={internalReviewQuery.data}
            onClose={() => setEditCollectionDraftId(null)}
            onDraftSubmit={updateDraftMutation.mutate}
            onCollectionSubmit={updateCollectionMutation.mutate}
            onInternalReviewSubmit={updateInternalReviewMutation.mutate}
            loading={
              editCollectionDraftQuery.isLoading ||
              updateDraftMutation.isLoading ||
              updateCollectionMutation.isLoading ||
              updateInternalReviewMutation.isLoading
            }
          />
        )}

      {/* grade collection drafts */}
      {gradeCollectionDraftiId &&
        editCollectionDraftQuery.data &&
        internalReviewQuery.data && (
          <CollectionDraftGrade
            collection={editCollectionQuery.data?.data}
            draft={editCollectionDraftQuery.data}
            internalReview={internalReviewQuery.data}
            approved={lastApprovedCollectionQuery.data?.data}
            onClose={() => setGradeCollectionDraftId(null)}
            onSubmit={gradeDraftMutation.mutate}
            onInternalReviewSubmit={updateInternalReviewMutation.mutate}
            loading={
              gradeDraftMutation.isLoading ||
              updateInternalReviewMutation.isLoading
            }
          />
        )}
    </AdminLayout>
  );
};
